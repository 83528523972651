import { ComponentProps, FC } from 'react';
import { Checkbox, FormGroup, Box, Text, useTombac } from 'tombac';
import { InfoIcon } from 'tombac-icons';
import { useMenu } from 'reducers/menuReducer';
import { SimpleTooltip } from 'components/AnalysisTable/SimpleTooltip';
import {
  prepareTripStats,
  TRIP_STATS_REGION_COUNT_THRESHOLD,
} from 'logic/analysis/analysisUtils';

interface Props {
  size?: ComponentProps<typeof Checkbox>['size'];
  regionsCount: number;
  paddingLeft?: string;
  showInfoIcon?: boolean;
}

export const TripStatsToggle: FC<Props> = ({
  size,
  regionsCount,
  paddingLeft,
  showInfoIcon,
}) => {
  const tombac = useTombac();
  const [menu, setMenu] = useMenu();
  const disabled = regionsCount > TRIP_STATS_REGION_COUNT_THRESHOLD;

  return (
    <>
      <FormGroup
        label={
          <Box $display="flex" $alignItems="center" $gridColumnGap="4px">
            <Text size="s">
              <b>Trip statistics</b>
            </Text>
            {showInfoIcon ? (
              <SimpleTooltip
                placement="left"
                usePortal
                content={
                  <Box $maxWidth="400px" $padding="5px">
                    <Text $color={tombac.color('neutral', 100)} fontSize={12}>
                      Trip Statistics is an optional feature that displays
                      additional data on trips between selected zones, including
                      trip length, start time, duration (in minutes), and
                      average speed. This data is available in the "Flows
                      Explorer" tab of the analysis results.
                      <br />
                      <br />
                      If this information is not needed, we recommend disabling
                      this feature before requesting the report, as doing so
                      will significantly improve the processing time of your
                      analyses.
                    </Text>
                  </Box>
                }
              >
                <InfoIcon size="xs" />
              </SimpleTooltip>
            ) : null}
          </Box>
        }
      >
        <Box
          title={
            disabled
              ? `Trip stats cannot be enabled for analyses containing more than ${TRIP_STATS_REGION_COUNT_THRESHOLD} regions.`
              : undefined
          }
        >
          <Checkbox
            variant="toggle"
            checked={prepareTripStats(regionsCount, menu.tripStats)}
            onChange={(e) => setMenu({ tripStats: e.target.checked })}
            size={size}
            disabled={disabled}
            $paddingLeft={paddingLeft}
          />
        </Box>
      </FormGroup>
    </>
  );
};

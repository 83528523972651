import DraftApi from 'api/DraftApi';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Heading } from 'tombac';
import { Title } from '../Layout/Title';
import './AnalysisTable.css';
import { AnalysisCancelModal } from './AnalysisCancelationModal';
import {
  AnalysisNavBar,
  AnalysisNavbarCounters,
} from './AnalysisNavbarCounters';
import { AnalysisTable } from './AnalysisTable';
import { DraftsList } from './DraftList';
import { useAnalysisPage } from './useAnalysisPage';
import { ReportsTrialBox } from './ReportsTrialBox';
import { OrbisNewBanner } from 'components/UI/Banner/OrbisNewBanner';

export type ListType = 'reports' | 'drafts' | 'archived';

interface AnalysisTablePageProps {
  path?: string;
  title?: string;
  adminView?: boolean;
  showDrafts?: boolean;
}
export const AnalysisTablePage: React.FC<AnalysisTablePageProps> = ({
  path = '/dashboard',
  title = 'My reports',
  adminView = false,
  showDrafts = true,
}) => {
  const location = useLocation();
  const [showBanner, setShowBanner] = useState(true);
  const [listType, setListType] = useState<ListType>(
    location.pathname === `${path}/drafts` && showDrafts ? 'drafts' : 'reports',
  );

  const [cancelAnalysisModal, setCancelAnalysisModal] = useState<{
    name: string;
    id: number;
  } | null>(null);

  const cancel = async (id: number) => {
    await analysisPage.cancel(id);
    setCancelAnalysisModal(null);
  };
  const openCancelModal = (name: string, id: number) => {
    setCancelAnalysisModal({ name, id });
  };
  const closeModal = () => {
    setCancelAnalysisModal(null);
  };

  const drafts = DraftApi.useDrafts();
  const analysisPage = useAnalysisPage({
    adminView,
    archivedOnly: false,
    refetchInterval: listType === 'reports' ? 5000 : undefined,
  });
  const analysisPageArchived = useAnalysisPage({
    adminView,
    archivedOnly: true,
  });
  const navCounters = useMemo(
    (): AnalysisNavbarCounters => ({
      reports: analysisPage.data?.numberOfAllAnalyses ?? 0,
      drafts: drafts?.length ?? 0,
      archived: analysisPageArchived.data?.numberOfAllAnalyses ?? 0,
    }),
    [
      analysisPage.data?.numberOfAllAnalyses,
      analysisPageArchived.data?.numberOfAllAnalyses,
      drafts?.length,
    ],
  );

  const handleListTypeChange = useCallback(
    (listType: ListType) => {
      setListType(listType);
      for (const page of [analysisPage, analysisPageArchived]) {
        page.setPage(1);
        page.setCurrentTab('All');
      }
    },
    [analysisPage, analysisPageArchived],
  );

  return (
    <div className="AnalysisTable__container">
      {cancelAnalysisModal && (
        <AnalysisCancelModal
          onClose={closeModal}
          onCancel={() => cancel(cancelAnalysisModal.id)}
          analysisName={cancelAnalysisModal.name}
          analysisId={cancelAnalysisModal.id}
        />
      )}
      {showBanner && (
        <OrbisNewBanner onCloseBanner={() => setShowBanner(false)} />
      )}
      <div className="AnalysisTable__content">
        <Title titleSuffix={title} />
        <Heading level={3} fontWeight="bold">
          {title}
        </Heading>
        {listType === 'reports' ? <ReportsTrialBox /> : null}
        <AnalysisNavBar
          path={path}
          listType={listType}
          onChange={handleListTypeChange}
          showDrafts={showDrafts}
          counters={navCounters}
        />
        {listType === 'reports' ? (
          <AnalysisTable
            analysisPage={analysisPage}
            openCancelModal={openCancelModal}
            archived={false}
            adminView={adminView}
          />
        ) : null}
        {listType === 'drafts' ? <DraftsList drafts={drafts} /> : null}
        {listType === 'archived' ? (
          <AnalysisTable
            analysisPage={analysisPageArchived}
            openCancelModal={openCancelModal}
            archived={true}
            adminView={adminView}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AnalysisTablePage;

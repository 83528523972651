import mapboxgl from 'mapbox-gl';
import React from 'react';
import {
  getEmptyStyle,
  GlMapProps,
  GlMap as LegolandGlMap,
} from 'legoland-shared';
import './GlMap.css';

export const apiKey = '1ncwaIygtJ0KrjH5ssohlEKUGFf7G5Dv';
const LOCAL_STORAGE_ID = 'tomtom-od-map-style';

export const getMapboxMap = (): mapboxgl.Map =>
  new mapboxgl.Map({
    container: document.createElement('div'),
    style: getEmptyStyle(apiKey),
    center: [-9.445531804936309, 35.4606814723237],
    zoom: 1.5,
    minZoom: 1,
    maxZoom: 20,
    preserveDrawingBuffer: true,
  });

const GlMap: React.FC<
  Omit<GlMapProps, 'apiKey' | 'mapStyleSettings' | 'onMapStyleSettingsChange'>
> = (props) => {
  return (
    <LegolandGlMap
      apiKey={apiKey}
      mapStyleSettingsLocalStorageId={LOCAL_STORAGE_ID}
      {...props}
    />
  );
};

export default GlMap;

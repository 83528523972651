import { FC } from 'react';
import { useAnalysisContext } from '../AnalysisViewPage';
import { RegionDownloadButton } from './RegionDownloadButton';

export const RegionDownload: FC = () => {
  const { analysis } = useAnalysisContext();

  return (
    <RegionDownloadButton
      fileName={analysis.info.name}
      regions={analysis.regions}
    />
  );
};

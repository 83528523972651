import { UserRole } from 'legoland-sdk/dist/experimental';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useUser } from 'user';

interface Props {
  children: React.ReactNode;
}

export const SupportOnly: React.FC<Props> = ({ children }) => {
  const user = useUser();
  if (user?.role !== UserRole.Support && user?.role !== UserRole.Admin) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};

import { lineString } from '@turf/turf';
import { AnalysisType } from 'model/AnalysisDto';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useMenu } from 'reducers/menuReducer';
import { SelectedLinkRequest, useLinkRequest } from './linkRequests';
import {
  applyExclusions,
  eachDayFromExcludedDays,
  toCorrectFormat,
} from 'components/AnalysisNew/Date/utils';
import { invertDays, shortStringDayToDayOfWeek } from 'model/DayOfWeek';
import { dateToDto } from 'logic/time/dateFormat';
import { DateRangeFromDto } from 'components/AnalysisNew/DateRanges/DateRange';

export const SelectedLinkRequestPage: React.FC = () => {
  const [, setMenu, { resetMenu }] = useMenu();
  const request = useLinkRequest<SelectedLinkRequest>();

  useEffect(() => {
    request.timeRanges?.forEach((tr) => {
      if (tr.startTime === '00:00' && tr.endTime === '24:00') {
        tr.startTime = '00:00';
        tr.endTime = '00:00';
      }
      if (tr.endTime === '24:00') {
        tr.endTime = '00:00';
      }
    });

    request.dateRanges?.forEach((dr) => {
      if (!dr.exclusions) {
        dr.exclusions = [];
      }

      if (dr.excludedDaysOfWeek) {
        const dateRange: DateRangeFromDto = {
          start: new Date(toCorrectFormat(dr.startDate)),
          end: new Date(toCorrectFormat(dr.endDate)),
          exclusions: [],
        };

        const excludedDaysOfWeek = dr.excludedDaysOfWeek
          .map((it) => shortStringDayToDayOfWeek(it))
          .filter((it) => it !== undefined);

        const exclusionsFromExcludedDays = eachDayFromExcludedDays(
          dateRange,
          invertDays(excludedDaysOfWeek),
        ).map(dateToDto);

        dr.exclusions = applyExclusions(
          dr.exclusions,
          exclusionsFromExcludedDays,
        );
      }
    });

    resetMenu();
    setMenu({
      name: request.name ?? 'Selected link report',
      type: AnalysisType.SelectedLink,
      mapVersion: request.mapVersion,
      mapType: request.mapType,
      timeRanges: request.timeRanges ?? [],
      dateRanges: request.dateRanges ?? [],
      links: request.link ? [lineString(request.link)] : [],
    });
  }, []);

  return <Redirect to="/new/dates" />;
};

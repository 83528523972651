import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import styled from 'styled-components';
import { Box, Button, FormGroup, RadioGroup, Text, useToasts } from 'tombac';
import { BackIcon, ChevronSLeftIcon, FiltersIcon } from 'tombac-icons';
import { useMap } from 'legoland-shared';
/* eslint-disable @typescript-eslint/no-this-alias */
import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import {
  Scenario,
  useScenario,
} from 'components/AnalysisViewPage/MapFlowsPro/logic/scenario';
import ViewPageContent from 'components/AnalysisViewPage/ViewPageContent/ViewPageContent';
import { Title } from 'components/Layout/Title';
import GlMap from 'components/Map/GlMap';
import { MapScreenshotButton } from 'components/Map/MapScreenshotButton';
import { centerOnRegions } from 'components/Map/mapUtils';
import DateTimeSelector from 'components/UI/DateTimeSelector/DateTimeSelector';
import { Analysis } from 'model/AnalysisDto';
import { SegmentStats, SelectedLinkUnit, SelectedSegment, View } from 'model/SelectedLink';
import { analysisUrl } from 'logic/analysis/analysisUtils';
import { DownloadSelectedLink } from './DownloadSelectedLink';
import { LinkNode, TreeType } from './LinkNode';
import { Palette, PaletteScale } from './palette/ColorPalette';
import { ColorPalettePicker } from './palette/ColorPalettePicker';
import { defaultColorPalettes } from './palette/defaultColorPalettes';
import { SelectedLinkTree } from './SelectedLinkTree';
import { SliderInput } from './SliderInput';
import { useMemo, useState } from 'react';
import { DisplayRegions } from 'components/Map/Layers/DisplayRegions';
import { useSelectedLinkResult } from './useSelectedLinkResult';
import {
  defaultSelectedLinkView,
  findScaleMaxPercent,
  isSelectedLinkRegion,
} from './utils';
import { useIsAdmin } from 'user';
import { ShowDebugTracesButton } from './Debug/ShowDebugTracesButton';
import { MapTypes } from 'reducers/menuReducer';
import { analysisToMapTypeName } from 'components/AnalysisViewPage/utils';
import { ControlsSection } from './SelectedLinkPage.style';
import { SelectedLinkCollapsibleSection } from './SelectedLinkColpsableSection';
import { useSegmentsStats } from './useSegmentsStats';
import SelectedLinkContextMenu, {
  Direction,
} from './ContextMenu/SelectedLinkContextMenu';

const mapCornerContainerWidth = 360;
const MapCornerContainer = styled.div`
  width: ${mapCornerContainerWidth}px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: #fff;
  border-right: solid 1px #e5e5e5;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
`;

const ToggleButton = styled(Button)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  position: absolute;
  right: -32px;
  top: 20px;
  width: 17px;
`;

interface MapOverlayElementsProps {
  analysis: Analysis;
  scenario: Scenario;
  setScenario: React.Dispatch<React.SetStateAction<Scenario>>;
  view: View;
  setView: React.Dispatch<React.SetStateAction<View>>;
  unit: SelectedLinkUnit;
  setUnit: React.Dispatch<React.SetStateAction<SelectedLinkUnit>>;
  minPercent: number;
  setMinPercent: React.Dispatch<React.SetStateAction<number>>;
  minTrips: number;
  setMinTrips: React.Dispatch<React.SetStateAction<number>>;
  palette: Palette;
  setPalette: React.Dispatch<React.SetStateAction<Palette>>;
  scale: PaletteScale;
  setScale: React.Dispatch<React.SetStateAction<PaletteScale>>;
  treeIn: LinkNode | undefined;
  treeOut: LinkNode | undefined;
  isSelectedLinkRegionAnalysis: boolean;
  clearSelectedSegments: () => void;
}

const MapOverlayElements: React.FC<MapOverlayElementsProps> = ({
  analysis,
  scenario,
  setScenario,
  view,
  setView,
  unit,
  setUnit,
  minPercent,
  setMinPercent,
  minTrips,
  setMinTrips,
  palette,
  setPalette,
  scale,
  setScale,
  treeIn,
  treeOut,
  isSelectedLinkRegionAnalysis,
  clearSelectedSegments,
}) => {
  const { map } = useMap();

  React.useEffect(() => {
    centerOnRegions(map, analysis.regions);
  }, []);

  const [menuHidden, setMenuHidden] = useState(false);
  const isAdmin = useIsAdmin();
  const { search } = useLocation();

  const unitOptions = [
    { label: 'Percent', value: SelectedLinkUnit.Percent },
    { label: 'Trips', value: SelectedLinkUnit.Trips },
  ];

  const backPathname = analysisUrl(
    analysis.info.type,
    analysis.info.id,
    location.pathname.includes('/share/') ? 'share' : 'view',
  );

  let viewOptions = [
    { value: View.INCOMING, label: 'Incoming' },
    { value: View.OUTGOING, label: 'Outgoing' },
  ];

  if (!isSelectedLinkRegionAnalysis) {
    viewOptions = [{ value: View.ALL, label: 'All' }, ...viewOptions];
  }

  return (
    <>
      <MapCornerContainer
        style={{
          transform: menuHidden
            ? `translateX(-${mapCornerContainerWidth}px)`
            : 'translateX(0px)',
        }}
      >
        <ToggleButton
          onClick={() => {
            setMenuHidden(!menuHidden);
          }}
          shape="circle"
          size="s"
          variant="accent"
        >
          <ChevronSLeftIcon
            style={{
              transform: menuHidden ? 'rotate(180deg)' : 'rotate(0)',
              transitionDuration: '0.2s',
              transitionTimingFunction: 'ease-in-out',
            }}
          />
        </ToggleButton>
        <ControlsSection white>
          <Box $display="flex" $justifyContent="space-between">
            <Link to={backPathname + search}>
              <Button
                $ml="-1.5sp"
                prepend={<BackIcon />}
                prependedHoverEffect="anim-backward"
                size="s"
                variant="flat"
              >
                Back
              </Button>
            </Link>
            <Box>
              {isAdmin && analysis.info.tripsDebug && (
                <ShowDebugTracesButton
                  analysis={analysis}
                  scenario={scenario}
                />
              )}
              <DownloadSelectedLink scenario={scenario} analysis={analysis} />
            </Box>
          </Box>
          <Text altFont fontSize={16} fontWeight="bold" $mt="1.5sp">
            {analysis.info.name}
          </Text>
        </ControlsSection>
        <ControlsSection>
          <DateTimeSelector
            scenario={scenario}
            onChange={setScenario}
            analysis={analysis}
            row
          />
        </ControlsSection>
        <SelectedLinkCollapsibleSection
          label={
            <>
              <FiltersIcon />
              <Text
                $color="inherit"
                $ml="1sp"
                altFont
                as="div"
                fontWeight="bold"
                fontSize={14}
              >
                Filters
              </Text>
            </>
          }
        >
          <FormGroup
            label="Trips direction through the selected link"
            $mb="2sp"
          >
            <RadioGroup
              variant="horizontal"
              options={viewOptions}
              value={viewOptions.find((it) => it.value === view)}
              onChange={(e) => {
                clearSelectedSegments();
                setView(e?.value as View);
              }}
            />
          </FormGroup>
          <FormGroup label="Values">
            <RadioGroup
              variant="horizontal"
              options={unitOptions}
              value={unitOptions.find((it) => it.value === unit)}
              onChange={(e) => setUnit(e?.value as SelectedLinkUnit)}
            />
          </FormGroup>
          {unit === SelectedLinkUnit.Percent && (
            <FormGroup label="Min Percent" $mt="2sp">
              <SliderInput
                min={0}
                max={5}
                step={0.1}
                value={minPercent}
                onChange={setMinPercent}
              />
            </FormGroup>
          )}
          {unit === SelectedLinkUnit.Trips && treeIn && treeOut && (
            <FormGroup label="Min Trips" $mt="2sp">
              <SliderInput
                min={0}
                max={Math.max(treeIn.trips, treeOut.trips)}
                step={1}
                value={minTrips}
                onChange={setMinTrips}
              />
            </FormGroup>
          )}
        </SelectedLinkCollapsibleSection>
      </MapCornerContainer>
      {treeIn && treeOut && (
        <ColorPalettePicker
          palette={palette}
          onPaletteChange={setPalette}
          scale={scale}
          onScaleChange={setScale}
          unitLabel="%"
        />
      )}
    </>
  );
};

interface ContextMenu {
  lngLat: mapboxgl.LngLat;
  showOnlyDirection?: Direction;
}

interface WithResultProps {
  lngLat?: mapboxgl.LngLat;
  analysis: Analysis;
  view: View;
  unit: SelectedLinkUnit;
  minPercent: number;
  minTrips: number;
  selectedSegmentsIn: SelectedSegment[];
  setSelectedSegmentsIn: (
    segments: SelectedSegment[],
    lngLat?: mapboxgl.LngLat,
  ) => void;
  selectedSegmentsOut: SelectedSegment[];
  setSelectedSegmentsOut: (
    segments: SelectedSegment[],
    lngLat?: mapboxgl.LngLat,
  ) => void;
  palette: Palette;
  scale: PaletteScale;
  treeIn: LinkNode;
  treeOut: LinkNode;
  contextMenu: ContextMenu | undefined;
  setContextMenu: React.Dispatch<React.SetStateAction<ContextMenu | undefined>>;
  isSelectedLinkRegionAnalysis: boolean;
  clearSelectedSegments: () => void;
}

const WithResult: React.FC<WithResultProps> = ({
  analysis,
  view,
  unit,
  minPercent,
  minTrips,
  selectedSegmentsIn,
  setSelectedSegmentsIn,
  selectedSegmentsOut,
  setSelectedSegmentsOut,
  palette,
  scale,
  treeIn,
  treeOut,
  contextMenu,
  setContextMenu,
  isSelectedLinkRegionAnalysis,
  clearSelectedSegments,
}) => {
  const { map } = useMap();
  const [isIncomingLinkHovered, setIsIncomingLinkHovered] = useState(false);
  const [isOutgoingLinkHovered, setIsOutgoingLinkHovered] = useState(false);

  React.useEffect(() => {
    map.getCanvas().style.cursor =
      isIncomingLinkHovered || isOutgoingLinkHovered ? 'pointer' : '';
  }, [isOutgoingLinkHovered, isIncomingLinkHovered]);

  const mapTypeName = React.useMemo(
    (): MapTypes => analysisToMapTypeName(analysis),
    [analysis],
  );

  const getColor = React.useCallback(
    (value: number): string => Palette.getColor(palette, scale, value),
    [palette, scale],
  );

  const {
    selectedSegmentsStats: selectedSegmentsStatsIn,
    shouldShowNode: shouldShowNodeIn,
    nodeFilter: nodeFilterIn,
    setNodeFilter: setNodeFilterIn,
  } = useSegmentsStats(
    treeIn,
    selectedSegmentsIn,
    unit,
    minPercent,
    minTrips,
    isSelectedLinkRegionAnalysis,
    getColor,
  );

  const {
    selectedSegmentsStats: selectedSegmentsStatsOut,
    shouldShowNode: shouldShowNodeOut,
    nodeFilter: nodeFilterOut,
    setNodeFilter: setNodeFilterOut,
  } = useSegmentsStats(
    treeOut,
    selectedSegmentsOut,
    unit,
    minPercent,
    minTrips,
    isSelectedLinkRegionAnalysis,
    getColor,
  );

  const [hoverIn, setHoverIn] = React.useState<{ nodes: Set<LinkNode> }>({
    nodes: new Set(),
  });
  const [hoverOut, setHoverOut] = React.useState<{ nodes: Set<LinkNode> }>({
    nodes: new Set(),
  });

  const handleDirectionClick = React.useCallback((direction: Direction) => {
    setContextMenu((prev) =>
      prev ? { ...prev, showOnlyDirection: direction } : undefined,
    );
  }, []);

  const handleDirectionEnter = React.useCallback(
    (direction: Direction, node: LinkNode) => {
      if (direction === 'in') {
        setHoverIn({ nodes: new Set([node]) });
      } else {
        setHoverOut({ nodes: new Set([node]) });
      }
    },
    [],
  );

  const handleDirectionLeave = React.useCallback((direction: Direction) => {
    if (direction === 'in') {
      setHoverIn({ nodes: new Set() });
    } else {
      setHoverOut({ nodes: new Set() });
    }
  }, []);

  const showContextMenu = React.useMemo(
    (): boolean =>
      view === View.ALL &&
      !!contextMenu &&
      !contextMenu.showOnlyDirection &&
      !!selectedSegmentsStatsIn?.length &&
      !!selectedSegmentsStatsOut?.length,
    [
      view,
      contextMenu,
      selectedSegmentsStatsIn?.length,
      selectedSegmentsStatsOut?.length,
    ],
  );
  const getHideRootSegment = (
    hoverNodes: Set<LinkNode>,
    otherHoverNodes: Set<LinkNode>,
    otherStats?: SegmentStats[],
  ) =>
    showContextMenu
      ? hoverNodes.size === 0 && otherHoverNodes.size > 0
      : !contextMenu?.showOnlyDirection && otherStats?.length > 0;

  return (
    <>
      {showContextMenu &&
      selectedSegmentsStatsIn &&
      selectedSegmentsStatsOut ? (
        <SelectedLinkContextMenu
          lngLat={contextMenu?.lngLat}
          treeIn={treeIn}
          treeOut={treeOut}
          selectedSegmentsStatsIn={selectedSegmentsStatsIn}
          selectedSegmentsStatsOut={selectedSegmentsStatsOut}
          nodeFilterIn={nodeFilterIn}
          nodeFilterOut={nodeFilterOut}
          isSelectedLinkRegionAnalysis={isSelectedLinkRegionAnalysis}
          onDirectionClick={handleDirectionClick}
          onDirectionEnter={handleDirectionEnter}
          onDirectionLeave={handleDirectionLeave}
        />
      ) : null}
      {(view === View.ALL || view === View.INCOMING) &&
      treeIn !== undefined &&
      contextMenu?.showOnlyDirection !== 'out' ? (
        <SelectedLinkTree
          mapTypeName={mapTypeName}
          tree={treeIn}
          getColor={getColor}
          unit={unit}
          selectedSegments={selectedSegmentsIn}
          selectedSegmentsStats={selectedSegmentsStatsIn ?? []}
          hideRootSegment={getHideRootSegment(
            hoverIn.nodes,
            hoverOut.nodes,
            selectedSegmentsStatsOut,
          )}
          onSelectedSegmentsChange={setSelectedSegmentsIn}
          isSelectedLinkRegion={isSelectedLinkRegionAnalysis}
          view={view}
          onIsHoveredChange={setIsIncomingLinkHovered}
          shouldShowNode={shouldShowNodeIn}
          nodeFilter={nodeFilterIn}
          onNodeFilterChange={setNodeFilterIn}
          hideInfo={showContextMenu}
          hover={hoverIn}
          onHoverChange={setHoverIn}
        />
      ) : null}
      {(view === View.ALL || view === View.OUTGOING) &&
      treeOut !== undefined &&
      contextMenu?.showOnlyDirection !== 'in' ? (
        <SelectedLinkTree
          mapTypeName={mapTypeName}
          tree={treeOut}
          getColor={getColor}
          unit={unit}
          selectedSegments={selectedSegmentsOut}
          selectedSegmentsStats={selectedSegmentsStatsOut ?? []}
          hideRootSegment={getHideRootSegment(
            hoverOut.nodes,
            hoverIn.nodes,
            selectedSegmentsStatsIn,
          )}
          onSelectedSegmentsChange={setSelectedSegmentsOut}
          isSelectedLinkRegion={isSelectedLinkRegionAnalysis}
          view={view}
          onIsHoveredChange={setIsOutgoingLinkHovered}
          shouldShowNode={shouldShowNodeOut}
          nodeFilter={nodeFilterOut}
          onNodeFilterChange={setNodeFilterOut}
          hideInfo={showContextMenu}
          hover={hoverOut}
          onHoverChange={setHoverOut}
        />
      ) : null}
      <DisplayRegions
        onSelect={() => {
          clearSelectedSegments();
        }}
        dtoRegions={analysis.regions}
        layerId="selected-link-regions"
      />
    </>
  );
};

export const SelectedLinkPage: React.FC = () => {
  const { analysis } = useAnalysisContext();
  const { scenario, setScenario } = useScenario();
  const [view, setView] = useState<View>(defaultSelectedLinkView(analysis));
  const [unit, setUnit] = React.useState(SelectedLinkUnit.Percent);
  const [minPercent, setMinPercent] = useState(1.5);
  const [minTrips, setMinTrips] = useState(50);
  const [contextMenu, setContextMenu] = useState<ContextMenu | undefined>(
    undefined,
  );
  const [selectedSegmentsIn, setSelectedSegmentsIn] = useState<
    SelectedSegment[]
  >(() => []);
  const [selectedSegmentsOut, setSelectedSegmentsOut] = useState<
    SelectedSegment[]
  >(() => []);
  const [palette, setPalette] = React.useState(() => defaultColorPalettes[1]);
  const [scale, setScale] = React.useState(PaletteScale.makeMinMax(0, 100, 10));
  const treeIn = useSelectedLinkResult(
    analysis,
    scenario.dateRange,
    scenario.timeRange,
    TreeType.In,
  );
  const treeOut = useSelectedLinkResult(
    analysis,
    scenario.dateRange,
    scenario.timeRange,
    TreeType.Out,
  );

  const isSelectedLinkRegionAnalysis = useMemo(() => {
    return isSelectedLinkRegion(analysis);
  }, [analysis]);

  const clearSelectedSegments = React.useCallback(() => {
    setSelectedSegmentsIn([]);
    setSelectedSegmentsOut([]);
    setContextMenu(undefined);
  }, []);

  React.useEffect(() => {
    clearSelectedSegments();
  }, [scenario.dateRange, scenario.timeRange]);

  React.useEffect(() => {
    if (!(treeIn && treeOut)) return;
    setMinTrips(
      Math.floor(Math.max(treeIn.trips, treeOut.trips) * (minPercent / 100)),
    );
  }, [treeIn, treeOut]);

  React.useEffect(() => {
    if (treeIn && treeOut && isSelectedLinkRegionAnalysis) {
      setScale(
        PaletteScale.makeMinMax(
          0,
          findScaleMaxPercent(view === View.INCOMING ? treeIn : treeOut),
          10,
        ),
      );
    }
  }, [treeIn, treeOut]);

  const toast = useToasts();

  React.useEffect(() => {
    if (!treeIn || treeIn.trips !== 0) return;
    if (!treeOut || treeOut.trips !== 0) return;

    toast.addToast('No data to display', 'alert', {
      id: 'selected-link-no-data',
      autoDismiss: false,
      content: (
        <>
          No trips were observed for this link with selected date/time
          parameters
        </>
      ),
    });

    return () => {
      toast.dismissToast('selected-link-no-data');
    };
  }, [treeIn, treeOut]);

  const handleSelectedSegmentsInChange = React.useCallback(
    (segments: SelectedSegment[], lngLat?: mapboxgl.LngLat) => {
      setSelectedSegmentsIn(segments);
      setContextMenu(lngLat ? { lngLat } : undefined);
    },
    [],
  );

  const handleSelectedSegmentsOutChange = React.useCallback(
    (segments: SelectedSegment[], lngLat?: mapboxgl.LngLat) => {
      setSelectedSegmentsOut(segments);
      setContextMenu(lngLat ? { lngLat } : undefined);
    },
    [],
  );

  return (
    <ViewPageContent>
      <Title titleSuffix="Selected Link" />
      <GlMap
        mapModel={analysisToMapTypeName(analysis)}
        mapControlMenuButtons={
          <MapScreenshotButton filename={analysis.info.name} />
        }
        mapOverlayElements={
          <MapOverlayElements
            analysis={analysis}
            scenario={scenario}
            setScenario={setScenario}
            view={view}
            setView={setView}
            unit={unit}
            setUnit={setUnit}
            minPercent={minPercent}
            setMinPercent={setMinPercent}
            minTrips={minTrips}
            setMinTrips={setMinTrips}
            palette={palette}
            setPalette={setPalette}
            scale={scale}
            setScale={setScale}
            treeIn={treeIn}
            treeOut={treeOut}
            isSelectedLinkRegionAnalysis={isSelectedLinkRegionAnalysis}
            clearSelectedSegments={clearSelectedSegments}
          />
        }
      >
        {treeIn && treeOut ? (
          <WithResult
            contextMenu={contextMenu}
            setContextMenu={setContextMenu}
            analysis={analysis}
            view={view}
            unit={unit}
            minPercent={minPercent}
            minTrips={minTrips}
            selectedSegmentsIn={selectedSegmentsIn}
            setSelectedSegmentsIn={handleSelectedSegmentsInChange}
            selectedSegmentsOut={selectedSegmentsOut}
            setSelectedSegmentsOut={handleSelectedSegmentsOutChange}
            palette={palette}
            scale={scale}
            treeIn={treeIn}
            treeOut={treeOut}
            isSelectedLinkRegionAnalysis={isSelectedLinkRegionAnalysis}
            clearSelectedSegments={clearSelectedSegments}
          />
        ) : null}
      </GlMap>
    </ViewPageContent>
  );
};

import within from '@turf/boolean-within';
import { flattenEach } from '@turf/meta';
import { GeoJSONObject } from '@turf/turf';
import { AnalysisType } from 'model/AnalysisDto';
import { RegionDto, RegionDtoRole } from '../../../model/RegionDto';
import { isGeoJsonTooSmall, isTopologicallyValid } from './areaValidation';

export enum ValidationStatus {
  OK = 'OK',
  INVALID = 'INVALID',
}

export class ValidationResult {
  status: ValidationStatus;
  message: string;

  constructor(status: ValidationStatus, message?: string) {
    this.status = status;
    this.message = message;
  }
}

const validationOk = () => new ValidationResult(ValidationStatus.OK, '');
const validationInvalid = (message: string) =>
  new ValidationResult(ValidationStatus.INVALID, message);

export const regionOutside = (region: GeoJSONObject, area: any) => {
  let isWithin = false;
  flattenEach(region, (g) => {
    if (isWithin) {
      return;
    }
    flattenEach(area, (areaGeometry) => {
      if (within(g, areaGeometry)) {
        isWithin = true;
      }
    });
  });
  return !isWithin;
};

function validateRegion(
  region: RegionDto,
  type: AnalysisType,
  allowedArea?: any,
) {
  if (
    type === AnalysisType.FlowMatrix &&
    region.geometry.type !== 'Polygon' &&
    region.geometry.type !== 'MultiPolygon' &&
    region.geometry.type !== 'LineString'
  ) {
    return validationInvalid(
      `Unsuported geometry: ${region.geometry.type}. Suported geometries: Polygon, MultiPolygon, LineString.`,
    );
  }

  if (
    type === AnalysisType.SelectedLink &&
    region.geometry.type !== 'LineString' &&
    region.geometry.type !== 'Polygon'
  ) {
    return validationInvalid(
      `Unsupported geometry: ${region.geometry.type}. Supported geometries: LineString, Polygon.`,
    );
  }

  const validResult = validateGeometry(region, allowedArea);
  if (validResult.status !== ValidationStatus.OK) {
    return validResult;
  }

  if (type === AnalysisType.FlowMatrix) {
    if (isGeoJsonTooSmall(region)) {
      return validationInvalid(
        `Geometry is too small. Minimum geometry area is 100m2`,
      );
    }

    if (isRegionRolesInvalid(region)) {
      return validationInvalid(
        `Invalid feature property: regionRoles. Supported roles: ${RegionDtoRole.ORIGIN}, ${RegionDtoRole.DESTINATION}, ${RegionDtoRole.VIA}`,
      );
    }
  }

  return validationOk();
}

export const validateSelectedLinkRegion = (
  region: RegionDto,
  allowedArea?: any,
) => {
  if (region.geometry.type !== 'Polygon') {
    return validationInvalid(
      `Unsupported geometry: ${region.geometry.type}. Supported geometries: Polygon.`,
    );
  }

  const validResult = validateGeometry(region, allowedArea);
  if (validResult.status !== ValidationStatus.OK) {
    return validResult;
  }

  if (isGeoJsonTooSmall(region)) {
    return validationInvalid(
      `Geometry is too small. Minimum geometry area is 100m2`,
    );
  }

  return validationOk();
};

const validateGeometry = (
  region: RegionDto,
  allowedArea?: any,
): ValidationResult => {
  const topologicallyValidResult = isTopologicallyValid(region);
  if (topologicallyValidResult.status === ValidationStatus.INVALID) {
    return topologicallyValidResult;
  }

  if (allowedArea && regionOutside(region, allowedArea)) {
    return validationInvalid(`Geometry is outside of permitted area`);
  }

  return validationOk();
};

export const validRegionDtoRoles: RegionDtoRole[] = [
  RegionDtoRole.ORIGIN,
  RegionDtoRole.DESTINATION,
  RegionDtoRole.VIA,
];

export const isRegionRolesInvalid = (region: RegionDto): boolean => {
  if (region.properties.regionRoles !== undefined) {
    const { regionRoles } = region.properties;
    if (!Array.isArray(regionRoles) || regionRoles.length === 0) {
      return true;
    }
    if (regionRoles.some((el) => !validRegionDtoRoles.includes(el))) {
      return true;
    }
  }
  return false;
};

export { validateRegion };

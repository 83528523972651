import { Loading } from 'components/UI/Loading';
import { analysisUrl } from 'logic/analysis/analysisUtils';
import React from 'react';
import Star from 'react-feather/dist/icons/star';
import { useHistory } from 'react-router-dom';
import { Box, HFlex, Input, Label, ToggleGroup } from 'tombac';
import { SearchIcon, SpinnerIcon } from 'tombac-icons';
import { AnalysisInfo, AnalysisType } from '../../model/AnalysisDto';
import { Delayed } from '../UI/Delayed';
import { Spacer } from '../UI/FormUI';
import './AnalysisTable.css';
import { Pagination } from './Pagination';
import { PageSize } from './PageSize';
import { GoToPage } from './GoToPage';
import { ReactComponent as NoReportsImg } from 'svg/empty-results.svg';
import { AnalysisPageResponse } from './useAnalysisPage';
import { StatusFilter } from './StatusFilter';
import { AnalysisListItem } from './AnalysisListItem';
import LimitsApi from 'api/LimitsApi';

export type AnalysisPageTab = 'All' | 'Favorite';

interface NoResultsFoundProps {
  subtitle?: string | React.ReactNode;
}
export const NoResultsFound: React.FC<NoResultsFoundProps> = ({ subtitle }) => {
  return (
    <div className="analysis-list-empty">
      <h3 className="empty-title">No results found</h3>
      {subtitle ? <p className="empty-description">{subtitle}</p> : null}
      <NoReportsImg />
    </div>
  );
};

interface AnalysisTableProps {
  analysisPage: AnalysisPageResponse;
  openCancelModal: (name: string, id: number) => void;
  archived: boolean;
  adminView: boolean;
}

export const AnalysisTable: React.FC<AnalysisTableProps> = ({
  analysisPage,
  openCancelModal,
  archived,
  adminView = false,
}) => {
  const history = useHistory();
  const limits = LimitsApi.use();

  const tableHeader = (
    <div className="analysis-item analysis-list-header">
      <div className="analysis-item-name-wrapper">Report name</div>
      <div className="analysis-item-status">Status</div>
      <div className="analysis-item-date">Created on</div>
      <div className="analysis-item-duration">Duration</div>
      <div
        className="analysis-more"
        style={{ marginLeft: 'auto', marginRight: '0' }}
      />
    </div>
  );

  if (analysisPage.data === undefined) {
    return <Loading />;
  }

  const fromCount = (analysisPage.page - 1) * analysisPage.pageSize + 1;
  const toCount = Math.min(
    analysisPage.page * analysisPage.pageSize,
    analysisPage.data.numberOfAllAnalyses,
  );
  const totalCount = analysisPage.data.numberOfAllAnalyses;
  const list = analysisPage.data;

  const noFilters = analysisPage.filter.length === 0;

  return (
    <Box>
      <div className="analysis-list-filters">
        <Input
          placeholder="Search reports"
          onChange={(e) => analysisPage.setFilter(e.target.value)}
          value={analysisPage.filter}
          data-test="search-reports"
          $width="300px"
          prepend={<SearchIcon color="#ccc" />}
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />
        <StatusFilter
          statusFilterSet={analysisPage.statusFilter}
          setStatusFilterSet={analysisPage.setStatusFilter}
        />
        <HFlex $ml="3sp" $alignItems="center">
          <Label size="m">Type</Label>
          <ToggleGroup.Root
            $ml="1sp"
            value={analysisPage.types?.[0] ?? 'ALL'}
            onChange={(type) => {
              if (type === 'ALL') {
                analysisPage.setTypes(undefined);
              } else {
                analysisPage.setTypes([type as AnalysisType]);
              }
            }}
          >
            <ToggleGroup.Item value="ALL">All</ToggleGroup.Item>
            <ToggleGroup.Item value={AnalysisType.FlowMatrix}>
              Origin/Destination
            </ToggleGroup.Item>
            <ToggleGroup.Item value={AnalysisType.SelectedLink}>
              Selected Link
            </ToggleGroup.Item>
            {limits?.limits?.flowLinksEnabled && (
              <ToggleGroup.Item value={AnalysisType.FlowLinks}>
                Flow Links
              </ToggleGroup.Item>
            )}
          </ToggleGroup.Root>
        </HFlex>

        {!archived ? (
          <ToggleGroup.Root
            $ml="3sp"
            value={analysisPage.currentTab}
            onChange={(favoriteFilter) => {
              analysisPage.setPage(1);
              analysisPage.setCurrentTab(favoriteFilter as AnalysisPageTab);
            }}
          >
            <ToggleGroup.Item value="All">All</ToggleGroup.Item>
            <ToggleGroup.Item value="Favorite">Favorite</ToggleGroup.Item>
          </ToggleGroup.Root>
        ) : null}

        {analysisPage.isFetching && (
          <Delayed timeoutMs={300}>
            <Spacer />
            <SpinnerIcon spin size="lg" />
          </Delayed>
        )}
      </div>
      <div className="AnalysisTable-wrapper">
        {list.numberOfPages > 0 && (
          <Pagination
            page={analysisPage.page - 1}
            pageCount={list.numberOfPages}
            pageRangeDisplayed={2}
            onChange={(e) => analysisPage.setPage(e.selected + 1)}
            fromCount={fromCount}
            toCount={toCount}
            totalCount={totalCount}
            contentLeft={
              <PageSize
                pageSize={analysisPage.pageSize}
                onPageSizeChange={analysisPage.setPageSize}
              />
            }
            contentRight={
              <GoToPage
                page={analysisPage.page}
                onPageChange={analysisPage.setPage}
                numberOfPages={list.numberOfPages}
              />
            }
          />
        )}
        <div data-test-id="AnalysisList" className="analysis-list">
          {list.analysisInfos.length > 0 && tableHeader}
          {list.analysisInfos.map((analysis: AnalysisInfo, i: number) => (
            <div
              onClick={(e) => {
                const url = analysisUrl(analysis.type, analysis.id);
                if (e.ctrlKey || e.metaKey) {
                  window.open(url, '_blank');
                } else {
                  history.push(url);
                }
              }}
              key={i}
            >
              <AnalysisListItem
                analysis={analysis}
                canCancel={true}
                showOwner={adminView}
                isArchived={archived}
                openCancelModal={() =>
                  openCancelModal(analysis.name, analysis.id)
                }
                removeAsFavorite={() =>
                  analysisPage.setFavorite(analysis.id, false)
                }
                archive={() => analysisPage.archive(analysis.id)}
                restore={() => analysisPage.restore(analysis.id)}
                markAsFavorite={() =>
                  analysisPage.setFavorite(analysis.id, true)
                }
              />
            </div>
          ))}
          {((analysisPage.currentTab === 'All' && noFilters) || !noFilters) &&
          list.analysisInfos.length === 0 ? (
            <NoResultsFound subtitle="Try adjusting filters to find reports" />
          ) : null}
          {noFilters &&
          analysisPage.currentTab === 'Favorite' &&
          list.analysisInfos.length === 0 ? (
            <div className="analysis-list-empty">
              <h3 className="empty-title">No favorite reports</h3>
              <p className="empty-description">
                Use <Star size={20} /> button to mark analysis as favorite
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </Box>
  );
};

import { FC } from 'react';
import copy from 'copy-to-clipboard';
import { useToasts } from 'tombac';
import { AnalysisShareSection } from './AnalysisShareSection';
import { shareUrlWarning } from './AnalysisShare';

interface Props {
  searchQuery?: string;
}

export const AnalysisGenerateSharedLink: FC<Props> = ({ searchQuery = '' }) => {
  const toast = useToasts();
  const myUrl =
    window.location.href +
    (window.location.href.includes('?') ? '&' : '?') +
    searchQuery;

  const copyLink = () => {
    copy(myUrl);
    toast.addToast('Link copied to clipboard', 'success');
  };

  return (
    <AnalysisShareSection
      title="Shared link to the current view"
      subtitle={shareUrlWarning}
      url={myUrl}
      onCopy={copyLink}
    />
  );
};

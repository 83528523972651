import AnalysisApi, { RequestListParams } from 'api/AnalysisApi';
import { useDebounce } from 'hooks/useDebounced';
import React, { useEffect, useMemo, useState } from 'react';
import { useToasts } from 'tombac';
import {
  AnalysisPage,
  AnalysisStatus,
  AnalysisType,
} from '../../model/AnalysisDto';
import { useQuery, useQueryClient } from 'react-query';
import { AnalysisPageTab } from './AnalysisTable';

enum QueryKeys {
  Reports = 'analysis-list',
  ArchivedReports = 'analysis-archived-list',
}
export interface AnalysisPageResponse {
  page: number;
  data?: AnalysisPage;
  isFetching: boolean;
  isLoading: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setFavorite: (analysisId: number, favorite: boolean) => void;
  cancel: (id: number) => Promise<void>;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  statusFilter: Set<AnalysisStatus>;
  setStatusFilter: React.Dispatch<React.SetStateAction<Set<AnalysisStatus>>>;
  currentTab: AnalysisPageTab;
  setCurrentTab: React.Dispatch<React.SetStateAction<AnalysisPageTab>>;
  archive: (analysisId: number) => void;
  restore: (analysisId: number) => void;
  types?: AnalysisType[];
  setTypes: React.Dispatch<React.SetStateAction<AnalysisType[] | undefined>>;
}
export const useAnalysisPage = ({
  adminView,
  archivedOnly,
  refetchInterval,
}: {
  adminView?: boolean;
  archivedOnly?: boolean;
  refetchInterval?: number;
}): AnalysisPageResponse => {
  const key = archivedOnly ? QueryKeys.ArchivedReports : QueryKeys.Reports;
  const { addToast } = useToasts();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState('');
  const [types, setTypes] = useState<AnalysisType[]>();
  const [statusFilter, setStatusFilter] = useState<Set<AnalysisStatus>>(
    new Set(),
  );
  const [currentTab, setCurrentTab] = useState<AnalysisPageTab>('All');

  const debouncedFilter = useDebounce(filter, 300);

  const params = useMemo<RequestListParams>(
    () => ({
      page,
      pageSize,
      favoriteOnly: currentTab === 'Favorite',
      archivedOnly: archivedOnly ?? false,
      ownedOnly: !adminView,
      filter: debouncedFilter,
      statusFilterSet: statusFilter,
      types,
    }),
    [
      page,
      pageSize,
      currentTab,
      archivedOnly,
      adminView,
      statusFilter,
      types,
      debouncedFilter,
    ],
  );
  const { data, isLoading, isFetching } = useQuery(
    [key, params],
    () => AnalysisApi.getList(params),
    { keepPreviousData: true, refetchInterval },
  );

  useEffect(() => {
    if (page > (data?.numberOfPages ?? 1)) {
      setPage(1);
    }
  }, [data]);

  const queryClient = useQueryClient();

  const setFavorite = (analysisId: number, favorite: boolean) => {
    const apiFn = favorite ? AnalysisApi.fave : AnalysisApi.unfave;
    apiFn(analysisId).then(() => queryClient.invalidateQueries(key));
    queryClient.setQueryData([key, params], (old?: AnalysisPage) => {
      const newInfos = old?.analysisInfos.map((it) => {
        if (it.id === analysisId) {
          return { ...it, favorite };
        }
        return it;
      });
      return { ...old, analysisInfos: newInfos } as AnalysisPage;
    });
  };

  const archive = (analysisId: number) => {
    addToast('Archived analysis', 'success');
    AnalysisApi.archive(analysisId).then(() => {
      queryClient.invalidateQueries(QueryKeys.Reports);
      queryClient.invalidateQueries(QueryKeys.ArchivedReports);
    });
  };

  const restore = (analysisId: number) => {
    addToast('Restored analysis', 'success');
    AnalysisApi.restore(analysisId).then(() => {
      queryClient.invalidateQueries(QueryKeys.Reports);
      queryClient.invalidateQueries(QueryKeys.ArchivedReports);
    });
  };

  const cancel = async (id: number) => {
    await AnalysisApi.cancel(id);
    queryClient.invalidateQueries(key);
  };

  return {
    page,
    data,
    isFetching,
    isLoading,
    setPage,
    pageSize,
    setPageSize,
    setFavorite,
    cancel,
    filter,
    setFilter,
    setStatusFilter,
    statusFilter,
    currentTab,
    setCurrentTab,
    archive,
    restore,
    types,
    setTypes,
  };
};

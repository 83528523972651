import { Box, Text } from 'tombac';
import { LinkNode } from '../LinkNode';
import { SelectedSegment, NodeFilter, SegmentStats } from 'model/SelectedLink';
import { SegmentContainer } from '../SelectedLinkTree';
import { SelectedLinkInfoHeader } from './SelectedLinkInfoHeader';
import { SegmentInfo } from './SegmentInfo';

interface Props {
  tree: LinkNode;
  selectedSegments: SelectedSegment[];
  selectedSegmentsStats: SegmentStats[];
  nodeFilter: NodeFilter;
  setSelectedSegments: (it: SelectedSegment[]) => void;
  setNodeFilter: (nodeFilter: NodeFilter) => void;
  setHover: (h: any) => void;
}

export const LinkInfo: React.FC<Props> = ({
  tree,
  selectedSegments,
  selectedSegmentsStats,
  nodeFilter,
  setSelectedSegments,
  setNodeFilter,
  setHover,
}: Props) => {
  const stats = selectedSegmentsStats.filter(
    (segment) => segment?.totalTrips !== 0,
  );
  return (
    <>
      {stats.map((segment) => (
        <SegmentContainer key={segment?.coords.join('')}>
          <SelectedLinkInfoHeader
            treeType={tree.type}
            segment={segment}
            selectedSegments={selectedSegments}
            setSelectedSegments={setSelectedSegments}
            maxTrips={tree.trips}
            isSelectedLinkRegion={false}
          />
          <Box $p="1sp 2sp">
            <Text fontWeight={500}>Paths:</Text>
            <Box>
              {segment?.paths.map((it, index) => (
                <SegmentInfo
                  key={index}
                  nodeFilter={nodeFilter}
                  setNodeFilter={setNodeFilter}
                  setHover={setHover}
                  path={it}
                  index={index}
                  nodesCount={stats.reduce<number>(
                    (acc, val) => acc + val.paths.length,
                    0,
                  )}
                />
              ))}
            </Box>
          </Box>
        </SegmentContainer>
      ))}
    </>
  );
};

import { useEffect, useRef } from 'react';

/**
 * Calls callback repeatedly and when dependencies change
 */
export const useTimerFunction = (
  callback: () => void,
  intervalMs: number,
  dependencies: React.DependencyList,
) => {
  const fnRef = useRef(callback);

  useEffect(() => {
    fnRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const id = setInterval(() => {
      fnRef.current();
    }, intervalMs);
    return () => {
      clearInterval(id);
    };
  }, [intervalMs]);

  useEffect(() => {
    fnRef.current();
  }, dependencies);
};

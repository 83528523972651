import { useMemo } from 'react';
import { Select, Text, tombac } from 'tombac';

interface Option {
  value: number;
  label: string;
}

interface Props {
  pageSizes?: number[];
  pageSize: number;
  onPageSizeChange: (pageSize: number) => void;
}

export const PageSize: React.FC<Props> = ({
  pageSizes = [10, 20, 50, 100, 200],
  pageSize,
  onPageSizeChange,
}) => {
  const pageSizeOptions = useMemo(
    () =>
      pageSizes.map((o) => ({
        value: o,
        label: o.toString(),
      })),
    [pageSizes],
  );

  return (
    <>
      <Text
        size="s"
        $color={tombac.color('neutral', 700)}
        fontFamily={tombac.altFontFamily}
      >
        <b>Items per page</b>
      </Text>
      <Select<Option>
        $width="96px"
        size="s"
        options={pageSizeOptions}
        value={pageSizeOptions.find((option) => option.value === pageSize)}
        onChange={(e: Option) => {
          onPageSizeChange(e.value);
        }}
      />
    </>
  );
};

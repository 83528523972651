import styled from 'styled-components';
import { Box, Button, Text, tombac } from 'tombac';
import { HideIcon, ShowIcon } from 'tombac-icons';
import { DefaultText, GreyText } from 'components/UI/Text.style';
import { Path } from './LinkRegionInfo';
import { NodeFilter } from 'model/SelectedLink';
import { SimpleTooltip } from 'components/AnalysisTable/SimpleTooltip';

const SegmentBox = styled.div`
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  font-family: ${tombac.fontFamily};
  height: 32px;
  padding: 0 16px;
  padding-right: 0;

  :hover {
    background: #f2f2f2;
  }
`;

export const SegmentViz = styled(Box)<{
  path: Path;
  nodeFilter: NodeFilter;
  width?: number;
}>`
  height: ${({ path }) => 4 + path.style['line-width']}px;
  width: ${({ width = 60 }) => width}px;
  border-radius: 10px;
  border: 1px solid #fff;
  background-color: ${({ path, nodeFilter }) =>
    !nodeFilter.exclude.has(path.node) ? path.style['line-color'] : '#ddd'};
`;

interface Props {
  nodeFilter: NodeFilter;
  setNodeFilter: (nodeFilter: NodeFilter) => void;
  setHover: (h: any) => void;
  path: Path;
  index: number;
  maxWidth?: number;
  nodesCount: number;
}

export const SegmentInfo: React.FC<Props> = ({
  nodeFilter,
  setNodeFilter,
  setHover,
  path,
  index,
  nodesCount,
}) => {
  const showHideDisabled =
    !nodeFilter.exclude.has(path.node) &&
    nodeFilter.exclude.size === nodesCount - 1;

  const isHidden = nodeFilter.exclude.has(path.node);

  return (
    <SegmentBox
      key={path.index}
      onMouseEnter={() => setHover({ nodes: new Set([path.node]) })}
      onMouseLeave={() => setHover({ nodes: new Set() })}
      style={{
        opacity: nodeFilter.exclude.has(path.node) ? 0.5 : 1,
      }}
    >
      <Box $width="2sp">{index + 1}</Box>
      <SegmentViz path={path} nodeFilter={nodeFilter} $margin="0 10px" />
      <DefaultText $width="100px">
        {path.style.trips.toLocaleString()} trips
      </DefaultText>
      <GreyText>{path.style.percent.toFixed(2)}%</GreyText>
      <Button
        $ml="auto"
        $mr="0.5sp"
        onClick={() => {
          const hasNode = nodeFilter.exclude.has(path.node);
          if (hasNode) {
            nodeFilter.exclude.delete(path.node);
          } else {
            nodeFilter.exclude.add(path.node);
          }

          setNodeFilter({ ...nodeFilter });
        }}
        shape="circle"
        size="xs"
        variant="flat"
        theme={{ ripple: { fill: '--white' } }}
        disabled={showHideDisabled}
        style={{ cursor: showHideDisabled ? 'not-allowed' : undefined }}
      >
        <SimpleTooltip
          content={
            <Text $maxWidth="220px" variant="inverted">
              {showHideDisabled
                ? `Cannot hide ${
                    nodesCount === 1 ? 'the only' : 'the last'
                  } visible path.${
                    nodesCount > 1
                      ? ' Unhide at least one other path to hide this one.'
                      : ''
                  }`
                : isHidden
                ? 'Show path'
                : 'Hide path'}
            </Text>
          }
          placement="top"
          usePortal
        >
          <Text $color="inherit" as="span">
            {isHidden ? <HideIcon /> : <ShowIcon />}
          </Text>
        </SimpleTooltip>
      </Button>
    </SegmentBox>
  );
};

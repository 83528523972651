import { FC, useMemo } from 'react';
import { Button, Heading, Modal, Box } from 'tombac';
import { AnalysisType } from 'model/AnalysisDto';
import { AnalysisShare } from './AnalysisShare';
import { AnalysisGenerateSharedLink } from './AnalysisGenerateSharedLink';

interface AnalysisShareButtonProps {
  heading?: string;
  analysisId: number;
  path?: string;
  type: AnalysisType;
  onClose: () => void;
  searchQuery?: string | (() => string);
}

export const AnalysisShareModal: FC<AnalysisShareButtonProps> = ({
  heading = 'Share with others',
  analysisId,
  path,
  type,
  onClose,
  searchQuery,
}) => {
  const effectiveSearchQuery = useMemo((): string | undefined => {
    if (typeof searchQuery === 'function') {
      return searchQuery();
    }
    return searchQuery;
  }, [searchQuery]);

  return (
    <Modal isOpen onRequestClose={onClose}>
      <div
        className="AnalysisListItem__cancel-modal"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Heading level={4} $marginTop="0">
          {heading}
        </Heading>
        <Box
          $display="grid"
          $gridRowGap="20px"
          $margin="20px 0"
          $width="520px"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {window.location.href.includes('/share/') ? (
            <AnalysisGenerateSharedLink searchQuery={effectiveSearchQuery} />
          ) : (
            <AnalysisShare
              analysisId={analysisId}
              path={path}
              type={type}
              searchQuery={effectiveSearchQuery}
            />
          )}
        </Box>
        <Box $display="flex">
          <Button variant="primary" onClick={onClose} $marginLeft="auto">
            Done
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

import React, { MouseEventHandler, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button, Heading } from 'tombac';
import { CancelIcon } from 'tombac-icons';

const DebugWindowContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  padding: 10px;
  z-index: 99999999999;
  display: flex;
  flex-direction: column;
  opacity: 0.4;

  :hover {
    opacity: 1;
  }
`;

const DebugWindowTitlebar = styled.div`
  margin: -10px;
  margin-bottom: 8px;
  height: 40px;
  background: #f2f2f2;
  display: flex;
  padding-left: 10px;
  align-items: center;
`;

interface DebugWindowProps {
  children: React.ReactNode;
  onClose: VoidFunction;
}

export const DebugWindow: React.FC<DebugWindowProps> = ({
  children,
  onClose,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const status = useRef<{ x: number; y: number }>();

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    // Init position in the middle of the screen
    const x = window.innerWidth / 2 - ref.current.offsetWidth / 2;
    const y = window.innerHeight / 2 - ref.current.offsetHeight / 2;
    ref.current.style.transform = `translate(${x}px, ${y}px)`;

    // Move the window to the mouse position
    const onMove = (e: MouseEvent) => {
      if (!ref.current || !status.current) return;
      e.preventDefault();

      ref.current.style.transform = `translate(${
        e.clientX - status.current.x
      }px, ${e.clientY - status.current.y}px)`;
    };
    const onUp = () => {
      status.current = undefined;
    };

    document.addEventListener('mousemove', onMove);
    document.addEventListener('mouseup', onUp);

    return () => {
      document.removeEventListener('mousemove', onMove);
      document.removeEventListener('mouseup', onUp);
    };
  }, []);

  const onMouseDown: MouseEventHandler = (e) => {
    if (!ref.current) {
      return;
    }

    const { x, y } = ref.current.getBoundingClientRect();
    status.current = { x: e.clientX - x, y: e.clientY - y };
  };

  return (
    <DebugWindowContainer ref={ref}>
      <DebugWindowTitlebar onMouseDown={onMouseDown}>
        <Heading level={5}>Debug</Heading>
        <Button $ml="auto" onClick={onClose} shape="circle" size="s">
          <CancelIcon />
        </Button>
      </DebugWindowTitlebar>
      {children}
    </DebugWindowContainer>
  );
};

import { FC } from 'react';
import { Button, ButtonVariant } from 'tombac';
import { DownloadIcon } from 'tombac-icons';
import { RegionDto } from 'model/RegionDto';
import { getRegionsGeoJson } from 'logic/region/regionDownload';

interface Props {
  fileName: string;
  regions: RegionDto[];
  variant?: ButtonVariant;
}

export const RegionDownloadButton: FC<Props> = ({
  fileName,
  regions,
  variant,
}) => {
  return (
    <Button
      variant={variant}
      prepend={<DownloadIcon />}
      onClick={() => getRegionsGeoJson(fileName, regions)}
      $width="100%"
    >
      Download as GeoJSON
    </Button>
  );
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MapTypes } from 'reducers/menuReducer';
import ResultSpinner from 'components/UI/ResultSpinner/ResultSpinner';
import {
  formatDateRange,
  formatTimeRange,
} from 'logic/analysis/dimensionUtils';
import { ReadableResult } from 'logic/ReadableResult';
import { RegionDto } from '../../../model/RegionDto';
import GlMap from '../../Map/GlMap';
import { centerOnRegions } from '../../Map/mapUtils';
import { ZoomInButton } from 'components/UI/UI';
import DateTimeSelector from '../../UI/DateTimeSelector/DateTimeSelector';
import { useAnalysisContext } from '../AnalysisViewPage';
import { calculateArea } from '../MapFlows/calculateArea';
import syncMaps, { copyMapPosition } from '../MapFlows/sync-move';
import { PartialResult } from '../MapFlowsPro/logic/PartialResult';
import { useScenario } from '../MapFlowsPro/logic/scenario';
import { ViewPageNavbar } from '../NavBar/ViewPageNavbar';
import ViewPageContent from '../ViewPageContent/ViewPageContent';
import './SpatialSankey.css';
import SpatialSankeyGl, { Strand } from './SpatialSankeyGl';
import { StrandPopup } from './StrandPopup';
import { analysisToMapTypeName } from '../utils';
import { SpatialSankeyMapControls } from './SpatialSankeyMapControls';
import MapScreenshot from 'components/Map/MapScreenshot';

const meters2tokm2 = (value: number) => value / 1000000;
const makeStrands = (
  result: ReadableResult | undefined,
  carsPerKm: boolean,
  regions: RegionDto[],
  regionAreas: number[],
): Strand[] => {
  if (result === undefined) {
    return undefined;
  }
  const size = regions.length;
  const flows = [];
  for (let o = 0; o < size; o++) {
    for (let d = 0; d < o; d++) {
      const trips = result.get(o, d).trips + result.get(d, o).trips;
      let divisor = 1;
      if (carsPerKm) {
        divisor = regionAreas[o] + regionAreas[d];
      }
      if (trips > 0) {
        flows.push({
          source: o,
          target: d,
          flow: trips / divisor,
        });
      }
    }
  }
  return flows;
};

export const SpatialSankey: React.FC = () => {
  const { analysis } = useAnalysisContext();
  const [filter, setFilter] = useState(5);
  const [carsPerKm, setCarsPerKm] = useState(false);
  const [regionNames, setRegionNames] = useState(true);
  const mainMapRef = useRef<mapboxgl.Map>(undefined);
  const compareMapRef = useRef<mapboxgl.Map>(undefined);
  const mapTypeName = React.useMemo(
    (): MapTypes => analysisToMapTypeName(analysis),
    [analysis],
  );
  const [selectedStrand, setSelectedStrand] = useState<Strand | undefined>(
    undefined,
  );

  const regionAreas = useMemo(
    () => analysis.regions.map(calculateArea).map(meters2tokm2),
    [analysis.regions],
  );

  // Center map on regions effect
  useEffect(() => {
    if (mainMapRef.current === undefined) {
      return;
    }
    centerOnRegions(mainMapRef.current, analysis.regions);
  }, [analysis.regions]);

  const { scenario, setScenario, scenarioActions } = useScenario();
  const { result, compareResult, loading } = PartialResult.use(
    analysis,
    scenario,
  );
  useEffect(() => {
    const allRegionIds = analysis.regions.map((_, i) => i);
    scenarioActions.setOrigins(allRegionIds);
    scenarioActions.setDestinations(allRegionIds);
  }, []);

  const compare = scenario.dateRangeCompare !== undefined;

  // Sync/unsynch maps effect
  useEffect(() => {
    if (!compare || !compareMapRef.current) {
      return;
    }

    let off: any;
    const syncViews = () => {
      window.dispatchEvent(new Event('resize'));
      copyMapPosition(mainMapRef.current, compareMapRef.current);
      off = syncMaps(mainMapRef.current, compareMapRef.current).off;
    };
    syncViews();
    return () => {
      if (off) {
        off();
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 0);
      }
    };
  }, [compare, compareResult]);

  const mainStrands = useMemo(
    () => makeStrands(result, carsPerKm, analysis.regions, regionAreas),
    [result, carsPerKm, analysis.regions, regionAreas],
  );

  const compareStrands = useMemo(
    () =>
      makeStrands(
        scenario.dateRangeCompare !== undefined ? compareResult : undefined,
        carsPerKm,
        analysis.regions,
        regionAreas,
      ),
    [compareResult, carsPerKm, analysis.regions, regionAreas],
  );

  const isCompareMode = useMemo(() => compare && compareStrands !== undefined, [
    compare,
    compareStrands,
  ]);

  const mapLayersMenuContent = (
    <SpatialSankeyMapControls
      regionNames={regionNames}
      setRegionNames={setRegionNames}
      carsPerKm={carsPerKm}
      setCarsPerKm={setCarsPerKm}
      filter={filter}
      setFilter={setFilter}
    />
  );

  return (
    <>
      <ViewPageNavbar visualisationName="Spatial Sankey" />
      <ViewPageContent>
        {loading && <ResultSpinner subtitle="Loading results..." />}
        <DateTimeSelector
          map
          comparable
          scenario={scenario}
          onChange={setScenario}
          analysis={analysis}
        />
        <div style={{ display: 'flex', width: compare ? '50%' : '100%' }}>
          <GlMap
            onMapRefChange={(c) => (mainMapRef.current = c)}
            mapModel={mapTypeName}
            hideNavigationControls={isCompareMode}
            hideMapControlMenuButtons={!!isCompareMode}
            mapControlMenuButtons={
              <>
                <ZoomInButton type="regions" regions={analysis.regions} />
                <MapScreenshot map={mainMapRef.current} />
              </>
            }
            mapControlsProps={{ mapLayersMenuContent }}
          >
            {mainStrands && (
              <>
                {selectedStrand && (
                  <StrandPopup
                    result={result}
                    strand={selectedStrand}
                    regions={analysis.regions}
                    onClose={() => setSelectedStrand(undefined)}
                  />
                )}
                <SpatialSankeyGl
                  flows={mainStrands}
                  dtoRegions={analysis.regions}
                  regionNames={regionNames}
                  filter={filter}
                  carsPerKm={carsPerKm}
                  selectedStrand={selectedStrand}
                  onStrandSelect={setSelectedStrand}
                  period={
                    '' +
                    formatDateRange(result.meta().dateRange) +
                    formatTimeRange(result.meta().timeRange)
                  }
                />
              </>
            )}
          </GlMap>
        </div>
        {isCompareMode && compare && compareStrands !== undefined ? (
          <div style={{ display: 'flex', width: '50%' }}>
            <GlMap
              onMapRefChange={(c) => (compareMapRef.current = c)}
              mapModel={analysisToMapTypeName(analysis)}
              mapControlMenuButtons={
                <>
                  <ZoomInButton type="regions" regions={analysis.regions} />
                  <MapScreenshot map={mainMapRef.current} />
                </>
              }
              mapControlsProps={{ mapLayersMenuContent }}
            >
              {selectedStrand && (
                <StrandPopup
                  result={compareResult}
                  strand={selectedStrand}
                  regions={analysis.regions}
                  onClose={() => setSelectedStrand(undefined)}
                />
              )}
              <SpatialSankeyGl
                selectedStrand={selectedStrand}
                onStrandSelect={setSelectedStrand}
                flows={compareStrands}
                dtoRegions={analysis.regions}
                regionNames={regionNames}
                filter={filter}
                carsPerKm={carsPerKm}
                period={
                  '' +
                  formatDateRange(compareResult.meta().dateRange) +
                  formatTimeRange(compareResult.meta().timeRange)
                }
              />
            </GlMap>
          </div>
        ) : null}
      </ViewPageContent>
    </>
  );
};

import { Input, Text, tombac } from 'tombac';

interface Props {
  numberOfPages: number;
  page: number;
  onPageChange: (pageSize: number) => void;
}

export const GoToPage: React.FC<Props> = ({
  numberOfPages,
  page,
  onPageChange,
}) => {
  return (
    <>
      <Text
        size="s"
        $color={tombac.color('neutral', 900)}
        fontFamily={tombac.altFontFamily}
      >
        Go to page
      </Text>
      <Input
        type="number"
        value={page}
        onChange={(e) => onPageChange(Number(e.target.value))}
        min={1}
        max={numberOfPages}
        $height="32px"
        $width="auto"
        $paddingRight="2px"
      />
    </>
  );
};

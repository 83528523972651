import { FC } from 'react';
import { useSettings } from 'user';

interface Props {
  value: number;
  squared?: boolean;
  toFixedLength?: number;
  format?: boolean;
}

const SquareKilometerToSquareMiles = 0.386102;
const KilometersToMiles = 0.621371;

export const Kilometers: FC<Props> = ({
  value,
  squared = true,
  toFixedLength = 2,
  format = true,
}) => {
  const units = useSettings()?.units ?? 'KM';

  let convertedValue = value;
  if (units === 'MI') {
    convertedValue = squared
      ? value * SquareKilometerToSquareMiles
      : value * KilometersToMiles;
  }

  const trimmedValue =
    convertedValue?.toFixed(toFixedLength) ?? (0).toFixed(toFixedLength);

  let formattedValue = trimmedValue + ' ' + units.toLowerCase();
  if (squared) {
    formattedValue += '²';
  }

  return <>{format ? formattedValue : trimmedValue}</>;
};

import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import { AnalysisStatus, AnalysisType } from 'model/AnalysisDto';
import { AnalysisMapModalActions } from '../analysisMap.types';
import { AnalysisCancelled } from './AnalysisCancelled';
import { AnalysisEnded } from './AnalysisEnded';
import { AnalysisFailed } from './AnalysisFailed';
import { AnalysisRejected } from './AnalysisRejected';
import { AnalysisRunning } from './AnalysisRunning';
import { AnalysisWaiting } from './AnalysisWaiting';

interface Props {
  isShared: boolean;
  modalActions: AnalysisMapModalActions;
}

export const Statuses = ({ isShared, modalActions }: Props) => {
  const { analysis } = useAnalysisContext();

  switch (analysis.info.status) {
    case AnalysisStatus.ENDED: {
      return isShared ? (
        <div data-test-id="analysis-ended" />
      ) : (
        <AnalysisEnded showSummary={modalActions.openSummaryWithActionsModal} />
      );
    }
    case AnalysisStatus.REJECTED: {
      return <AnalysisRejected />;
    }
    case AnalysisStatus.WAITING: {
      return (
        <AnalysisWaiting
          showCancelModal={modalActions.openCancelModal}
          isShared={isShared}
        />
      );
    }
    case AnalysisStatus.RUNNING: {
      return (
        <AnalysisRunning
          isShared={isShared}
          showCancelModal={modalActions.openCancelModal}
        />
      );
    }
    case AnalysisStatus.FAILED: {
      return (
        <AnalysisFailed
          errorMessage={
            [AnalysisType.FlowMatrix, AnalysisType.FlowLinks].includes(
              analysis.info.type,
            ) && analysis.info.tripStats
              ? `Report failed due to an error. It might be too big 
              for our processing capabilities at the moment. 
              We will investigate this further. In the meantime, 
              we advise rerunning this report without trip statistics.`
              : undefined
          }
        />
      );
    }
    case AnalysisStatus.CANCELLED: {
      return <AnalysisCancelled />;
    }
    default: {
      return null;
    }
  }
};

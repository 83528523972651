import * as React from 'react';
import { bearing } from '@turf/turf';
import { LinkNode } from './LinkNode';
import { makeSegmentStyle } from './useTreeRenderer';
import {
  NodeFilter,
  SegmentStats,
  SelectedLinkUnit,
  SelectedSegment,
} from 'model/SelectedLink';

export const useSegmentsStats = (
  tree: LinkNode,
  selectedSegments: SelectedSegment[],
  unit: SelectedLinkUnit,
  minPercent: number,
  minTrips: number,
  isSelectedLinkRegionAnalysis: boolean,
  getColor: (value: number) => string,
) => {
  const [nodeFilter, setNodeFilter] = React.useState<NodeFilter>({
    exclude: new Set(),
  });
  const shouldShowNode = React.useCallback(
    (node: LinkNode, ignoreHighlighted?: boolean): boolean => {
      const isWithinUnitFilter = (node: LinkNode) =>
        unit === SelectedLinkUnit.Percent
          ? node.trips / tree.trips >= minPercent / 100
          : node.trips >= minTrips;

      if (!isWithinUnitFilter(node)) {
        return false;
      }

      const selected = selectedSegments.map((it) => it.segmentHash);

      let paths: number[][] = selected
        .flatMap((it) => tree.getByHash(it))
        .filter((it) => isWithinUnitFilter(it))
        .filter((node) => {
          if (ignoreHighlighted) return true;

          const isExcluded = nodeFilter.exclude.has(node);
          return !isExcluded;
        })
        .map((it) => it.path);

      if (paths.length > 0) {
        return (
          paths.find((it) => LinkNode.isSameBranch(node.path, it)) !== undefined
        );
      }

      return !isSelectedLinkRegionAnalysis || selected.length === 0;
    },
    [
      unit,
      tree,
      minPercent,
      minTrips,
      selectedSegments,
      nodeFilter,
      isSelectedLinkRegionAnalysis,
    ],
  );

  const selectedSegmentsStats = React.useMemo(():
    | SegmentStats[]
    | undefined => {
    if (selectedSegments.length === 0) {
      return;
    }
    return selectedSegments
      .map((segment, index) => {
        const nodes = tree.getByHash(segment.segmentHash);
        if (nodes.length === 0) return;

        const activeNodes = nodes.filter((it) => shouldShowNode(it, true));
        const frc = activeNodes[0]?.frc;
        const totalTrips = activeNodes?.reduce((acc, it) => acc + it.trips, 0);
        const coords = nodes[0]?.coordinates as [number, number][];
        const paths = activeNodes
          .map((it, i) => ({
            node: it,
            name: 'Path ' + i,
            index: i,
            style: makeSegmentStyle(it.trips, tree.trips, getColor, unit),
          }))
          .sort((a, b) => b.node.trips - a.node.trips);

        return {
          index: index,
          totalTrips,
          frc,
          paths,
          coords,
          bearing: bearing(coords[0], coords[coords.length - 1]),
        };
      })
      .filter(
        (segment) => segment && segment.totalTrips !== 0,
      ) as SegmentStats[];
  }, [selectedSegments, tree, unit, shouldShowNode, getColor]);

  return { selectedSegmentsStats, shouldShowNode, nodeFilter, setNodeFilter };
};

import { featureCollection } from '@turf/helpers';
import { saveAs } from 'file-saver';
import { RegionDto } from 'model/RegionDto';

export const getRegionsGeoJson = (fileName: string, regions: RegionDto[]) => {
  const collection = featureCollection(regions);
  const blob = new Blob([JSON.stringify(collection, null, 2)], {
    type: 'application/json;charset=utf-8',
  });
  saveAs(blob, `${fileName}.json`);
};

export const stripValidationResult = (regions: RegionDto[]): RegionDto[] => {
  return regions.map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ properties: { validationResult, ...properties }, ...region }) => ({
      ...region,
      properties,
    }),
  );
};

import { FC } from 'react';
import { AsideSection } from 'components/UI/UI';
import { Box } from 'tombac';
import { ErrorIcon } from 'tombac-icons';
import { Header } from '../AnalysisMapSidebar.style';
import { Text } from './Statuses.style';

interface Props {
  errorMessage?: string;
}

export const AnalysisFailed: FC<Props> = ({
  errorMessage = 'Report failed due to error',
}) => {
  return (
    <AsideSection
      variant="alert"
      canHide={false}
      title={
        <Box
          $display="flex"
          $alignItems="center"
          $gap="6px"
          $mb="6px"
          $position="relative"
          $left="-6px"
        >
          <ErrorIcon color="#df1b12" size="lg" />
          <Header level={2}>Error</Header>
        </Box>
      }
    >
      <Box>
        <Text>{errorMessage}</Text>
      </Box>
    </AsideSection>
  );
};

import React from 'react';
import Helmet from 'react-helmet';

interface TitleProps {
  titleSuffix: string;
}

export const Title: React.FC<TitleProps> = ({ titleSuffix }) => {
  let title = 'TomTom O/D Analysis';

  if (titleSuffix) {
    title += ' | ' + titleSuffix;
  }
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import { AsideSection } from 'components/UI/UI';
import { RouteIcon } from 'tombac-icons';
import { RegionDownload } from '../../RegionDownload';
import { Value } from '../AnalysisMapSidebar.style';
import { metersToKm } from 'logic/unit';
import { LinkAsideTitle, LinkInfoContainer } from './Sections.style';

export const SelectedLink = () => {
  const { analysis } = useAnalysisContext();

  return (
    <AsideSection
      title={
        <LinkAsideTitle>
          <RouteIcon />
          Link
        </LinkAsideTitle>
      }
    >
      <LinkInfoContainer>
        {analysis.info.map?.version && (
          <Value>Map version: {analysis.info.map?.version}</Value>
        )}
        {analysis.info.bufferRadiusInMeters && (
          <Value>
            Radius: {metersToKm(analysis.info.bufferRadiusInMeters)} km
          </Value>
        )}
        <Value>Selected segments: {analysis.regions.length}</Value>
        <RegionDownload />
      </LinkInfoContainer>
    </AsideSection>
  );
};

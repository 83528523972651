import { PropsWithChildren, useCallback, useEffect } from 'react';
import { Button, Label } from 'tombac';
import { ForwardIcon } from 'tombac-icons';
import styled from 'styled-components';

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
  height: 24px;
  :focus {
    outline: none;
  }
`;

interface Props {
  label: string;
  currentValue: number;
  changeValue: (val: number) => void;
  maxValue: number;
  canSwitchByKeyboard: boolean;
  onFocus: VoidFunction;
  showArrows: boolean;
}

type ButtonProps = PropsWithChildren<{
  onClick: VoidFunction;
}>;

const SwitchValueButton = ({ children, onClick }: ButtonProps) => (
  <Button shape="circle" variant="flat" size="xs" $mr="0.5sp" onClick={onClick}>
    {children}
  </Button>
);

export const SelectSwitcher = ({
  label,
  currentValue,
  changeValue,
  maxValue,
  canSwitchByKeyboard,
  onFocus,
  showArrows,
}: Props) => {
  const shouldRenderButtons = maxValue !== 0 && showArrows;

  const goNext = useCallback(() => {
    if (currentValue + 1 <= maxValue) {
      changeValue(currentValue + 1);
    } else {
      changeValue(0);
    }
  }, [currentValue, maxValue]);

  const goBack = useCallback(() => {
    if (currentValue - 1 < 0) {
      changeValue(maxValue);
    } else {
      changeValue(currentValue - 1);
    }
  }, [currentValue, maxValue]);

  const handleKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      ev.preventDefault();
      if (ev.key === 'ArrowLeft') goNext();
      else if (ev.key === 'ArrowRight') goBack();
    },
    [goNext, goBack],
  );

  useEffect(() => {
    if (canSwitchByKeyboard)
      document.addEventListener('keydown', handleKeyDown);
    return () => {
      if (canSwitchByKeyboard)
        document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, canSwitchByKeyboard]);

  return (
    <LabelContainer onFocus={onFocus} tabIndex={-1}>
      <Label $mr="1sp">{label}</Label>
      {shouldRenderButtons && (
        <>
          <SwitchValueButton onClick={goBack}>
            <ForwardIcon rotate={180} />
          </SwitchValueButton>
          <SwitchValueButton onClick={goNext}>
            <ForwardIcon />
          </SwitchValueButton>
        </>
      )}
    </LabelContainer>
  );
};

import { DEFAULT_MAX_REGION_COUNT, LimitsWithUsage } from 'api/LimitsApi';
import { eachDayOfInterval, getDaysInYear } from 'date-fns';
import { Requirement } from 'hooks/useValidation';
import { dateToDto } from 'logic/time/dateFormat';
import { DayOfWeek } from 'model/DayOfWeek';
import { RegionDto, RegionDtoRole } from 'model/RegionDto';
import { DateRangeDto } from 'model/TimeDto';
import { count } from 'ramda';
import { MenuState } from 'reducers/menuReducer';
import { eachDayFromExcludedDays } from '../Date/utils';
import {
  DateRange,
  DateRangeFromDto,
  dateRangeFromDto,
  dateRangeFromLimit,
  isWithin,
} from '../DateRanges/DateRange';
import { isSameDateRangeDtoAsDateRange } from '../DateRanges/DateUtils';
import { isDateInActiveDays } from '../dateRangeUtils';
import { calculateArea } from '../RegionSelection/areaValidation';
import { validRegionDtoRoles } from '../RegionSelection/validation';
import { ANALYSIS_NAME_MAX_LENGTH } from '../../AnalysisViewPage/Map/AnalysisRename';
import { isRegionAnalysis } from 'logic/analysis/analysisUtils';

const countActiveDays = (
  dateRange: DateRangeFromDto,
  activeDays: DayOfWeek[],
) => {
  return count((d: Date) => isDateInActiveDays(d, activeDays))(
    eachDayOfInterval({ start: dateRange.start, end: dateRange.end }),
  );
};

export const nameRequirements: Requirement<{ menu: MenuState }>[] = [
  {
    label: 'You must specify report name',
    check: ({ menu }) => menu.name.length === 0,
  },
  {
    label: `Name cannot be longer than ${ANALYSIS_NAME_MAX_LENGTH} characters`,
    check: ({ menu }) => menu.name.length > ANALYSIS_NAME_MAX_LENGTH,
  },
];

export const regionNamesRequirements: Requirement<{ names: string[] }>[] = [
  {
    label: 'You must specify region name',
    check: ({ names }) => names.some((it) => it.length === 0),
  },
];

export const datesRequirements: Requirement<{
  menu: MenuState;
  limits?: LimitsWithUsage;
}>[] = [
  {
    label: 'Add date to go to the next step',
    check: ({ menu }) => menu.dateRanges.length === 0,
  },
  {
    label: 'You must specify at least one day of the week',
    check: ({ menu }) => menu.daysOfWeek.length === 0,
  },
  {
    label: 'Some of selected dates are outside available dates',
    check: ({ menu, limits }) => {
      const { from, to } = limits?.limits.dateRange ?? {};
      if (!from || !to) {
        return false;
      }
      return menu.dateRanges.some(
        (it) =>
          !isWithin(dateRangeFromDto(it), dateRangeFromLimit({ from, to })),
      );
    },
  },
  {
    label: (state) => {
      const dateRangesExceedingLimit = dateRangesExceedingOneYear(
        state.menu.dateRanges,
        state.menu.daysOfWeek,
      )[0];
      return `Date range cannot have more than ${maxDaysInDateRange(
        dateRangesExceedingLimit,
      )} days`;
    },
    check: (state) => {
      return (
        state.menu.dateRanges.length > 0 &&
        dateRangesExceedingOneYear(state.menu.dateRanges, state.menu.daysOfWeek)
          .length > 0
      );
    },
  },
];

const dateRangesExceedingOneYear = (
  dateRanges: DateRangeDto[],
  daysOfWeek: DayOfWeek[],
) =>
  dateRanges.filter((dateRange) => {
    const days = eachDayOfInterval({
      start: new Date(dateRange.startDate),
      end: new Date(dateRange.endDate),
    }).filter(
      (it) =>
        daysOfWeek.includes(Object.values(DayOfWeek)[it.getDay()]) == true,
    );
    return days.length > maxDaysInDateRange(dateRange);
  });

const maxDaysInDateRange = (dateRange: DateRangeDto) =>
  getDaysInYear(new Date(dateRange.startDate));

export const timesRequirements: Requirement<{ menu: MenuState }>[] = [
  {
    label: 'Add times to go to the next step',
    check: ({ menu }) => menu.timeRanges.length === 0,
  },
  {
    label: 'Some of selected times do not start and end at the full hour',
    check: ({ menu }) =>
      menu.timeRanges.some(
        (timeRanege) =>
          !timeRanege.startTime.endsWith('00') ||
          !timeRanege.endTime.endsWith('00'),
      ),
  },
  {
    label: 'Time condition must be specified',
    check: ({ menu }) => menu.timeRangeCondition === undefined,
  },
];

export const selectedLinkNotEmptyGeometryRequirements: Requirement<{
  menu: MenuState;
}>[] = [
  {
    label: 'Select a link or add region to go to the next step',
    check: ({ menu }) => menu.regions.length === 0 && menu.links.length === 0,
  },
];

export const regionsRequirements: Requirement<{ menu: MenuState }>[] = [
  {
    label: 'Add regions to go to the next step',
    check: ({ menu }) =>
      isRegionAnalysis(menu.type) && menu.regions.length === 0,
  },
];

export const selectedLinkRegionRequirements: Requirement<{
  menu: MenuState;
}>[] = [
  {
    label: 'Add region to go to the next step',
    check: ({ menu }) => menu.regions.length === 0,
  },
  {
    label: 'Selected link supports only one region',
    check: ({ menu }) => menu.regions.length > 1,
  },
  {
    label: 'Select at least one road class',
    check: ({ menu }) => menu.entrancesFrcs.length === 0,
  },
  {
    label: 'Region is invalid',
    check: ({ menu }) =>
      menu.regions.some(
        (region) => region.properties.validationResult.status === 'INVALID',
      ),
  },
];

export const selectedLinkRequirements: Requirement<{
  menu: MenuState;
}>[] = [
  {
    label: 'Select a link to go to the next step',
    check: ({ menu }) => menu.links.length === 0,
  },
];

const countODVRegions = (regions: RegionDto[]): number => {
  return regions.reduce<number>(
    (acc, region) =>
      validRegionDtoRoles.every((validRole) =>
        (region.properties.regionRoles ?? validRegionDtoRoles).includes(
          validRole,
        ),
      )
        ? acc + 1
        : acc,
    0,
  );
};

export const getRegionCountLimit = (
  limitsApiMaxRegionCount: number,
  regions: RegionDto[],
): number => {
  let limit = limitsApiMaxRegionCount;
  const regionsWithODVRolesCount = countODVRegions(regions);
  if (regionsWithODVRolesCount < regions.length) {
    limit = (limit - regionsWithODVRolesCount) * 3 + regionsWithODVRolesCount;
  }

  return Math.max(limitsApiMaxRegionCount, limit);
};

export const regionSelectionRequirements: Requirement<{
  regions: RegionDto[];
  limits?: LimitsWithUsage;
  zoneId?: string;
  coverage?: any;
  passMatrix: boolean;
}>[] = [
  {
    label: 'Regions number exceeded',
    check: (suspect) =>
      suspect.regions.length >
      getRegionCountLimit(
        suspect.limits?.limits?.maxRegionCount ?? DEFAULT_MAX_REGION_COUNT,
        suspect.regions,
      ),
  },
  {
    label: 'At least one region with role ORIGIN required',
    check: (suspect) => {
      if (!suspect.passMatrix) {
        return false;
      }
      return suspect.regions.every(
        (r) =>
          r.properties.regionRoles &&
          !r.properties.regionRoles.includes(RegionDtoRole.ORIGIN),
      );
    },
  },
  {
    label: 'At least one region with role DESTINATION required',
    check: (suspect) => {
      if (!suspect.passMatrix) {
        return false;
      }
      return suspect.regions.every(
        (r) =>
          r.properties.regionRoles &&
          !r.properties.regionRoles.includes(RegionDtoRole.DESTINATION),
      );
    },
  },
  {
    label: 'No regions with valid roles found',
    check: (suspect) => {
      if (suspect.passMatrix) {
        return false;
      }
      return suspect.regions.every(
        (r) =>
          r.properties.regionRoles && r.properties.regionRoles.length === 0,
      );
    },
  },
  {
    label: 'Regions area exceeded',
    check: (suspect) =>
      calculateArea(suspect.regions) > suspect.limits?.limits?.maxAreaSize,
  },
  {
    label: 'Regions area exceeded',
    check: (suspect) =>
      calculateArea(suspect.regions) >
      suspect.limits?.limits?.totalAreaInSquareKm,
  },
  {
    label: 'Create regions to add it to report',
    check: (suspect) => suspect.regions.length === 0,
  },
  {
    label: 'Timezone must be specified',
    check: (suspect) => !(suspect.zoneId?.length > 0),
  },
  {
    label: 'Some regions are invalid',
    check: (suspect) =>
      suspect.regions.some(
        (region) => region.properties.validationResult.status === 'INVALID',
      ),
  },
  {
    label: 'Pricing coverage limit exceeded',
    check: ({ limits, coverage }) => {
      const hasLimit = Number.isInteger(limits?.limits?.totalRoadNetwork);
      const remaining = hasLimit
        ? Math.max(
            0,
            limits.limits.totalRoadNetwork -
              limits.usage.totalRoadNetwork -
              (coverage ?? 0),
          )
        : undefined;

      return hasLimit ? remaining === 0 : false;
    },
  },
];

export const dateRangeModalRequirements: Requirement<{
  menu: MenuState;
  dateRange: DateRange;
  editingDateRangeIndex?: number;
  activeDays?: DayOfWeek[];
}>[] = [
  {
    label: 'You already selected this range',
    check: (suspect) => {
      const { dateRange, activeDays } = suspect;
      if (dateRange?.start && dateRange?.end) {
        const exclusionsForDays = eachDayFromExcludedDays(
          { ...dateRange, start: dateRange.start, end: dateRange.end },
          activeDays || Object.values(DayOfWeek),
        ).map((d) => dateToDto(d));
        const dateRangeWithAllExclusions: DateRange = {
          ...dateRange,
          exclusions: [...dateRange.exclusions, ...exclusionsForDays],
        };

        const sameRangeIndex = suspect.menu.dateRanges.findIndex((dateRange) =>
          isSameDateRangeDtoAsDateRange(dateRangeWithAllExclusions, dateRange),
        );
        if (suspect.editingDateRangeIndex !== undefined) {
          return (
            sameRangeIndex !== -1 &&
            sameRangeIndex !== suspect.editingDateRangeIndex
          );
        }
        return sameRangeIndex !== -1;
      }
      return false;
    },
  },
  {
    label: ({ dateRange }) =>
      `Date range cannot have more than ${
        dateRange.start ? getDaysInYear(dateRange.start) : 366
      } days`,
    check: ({ dateRange }) =>
      dateRange?.start && dateRange?.end
        ? eachDayOfInterval({ start: dateRange.start, end: dateRange.end })
            .length > getDaysInYear(dateRange.start)
        : false,
  },
  {
    label: 'Start date and end date cannot be empty',
    check: ({ dateRange }) => !dateRange?.start || !dateRange?.end,
  },
  {
    label: 'At least one day of the week must be selected',
    check: (suspect) => suspect?.activeDays?.length === 0,
  },
  {
    label: 'You cannot exclude all days from the date range',
    check: (suspect) => {
      const { start, end, exclusions, name } = suspect.dateRange;
      if (start && end && suspect.activeDays) {
        const numberOfDays = countActiveDays(
          { start, end, exclusions, name },
          suspect.activeDays,
        );
        return numberOfDays === suspect.dateRange.exclusions.length;
      }
      return false;
    },
  },
];

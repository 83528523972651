import { AnalysisType } from 'model/AnalysisDto';
import React, { useState } from 'react';
import { Button } from 'tombac';
import { ShareLinkIcon } from 'tombac-icons';
import { SimpleTooltip } from './SimpleTooltip';
import { AnalysisShareModal } from './AnalysisShareModal';

interface AnalysisShareButtonProps {
  size: 'small' | 'big';
  analysisId: number;
  type: AnalysisType;
}

export const AnalysisShareButton: React.FC<AnalysisShareButtonProps> = ({
  size,
  analysisId,
  type,
}) => {
  const [modal, setModal] = useState(false);

  const openModal = () => setModal(true);

  const button =
    size === 'small' ? (
      <SimpleTooltip content="Share" usePortal placement="top">
        <Button
          variant="flat"
          size="xs"
          shape="circle"
          onClick={openModal}
          data-test="analysis-item-share"
        >
          <ShareLinkIcon />
        </Button>
      </SimpleTooltip>
    ) : (
      <SimpleTooltip content="Share" placement="top" usePortal>
        <Button
          onClick={openModal}
          data-test="analysis-item-share"
          shape="circle"
          $background="transparent"
        >
          <ShareLinkIcon />
        </Button>
      </SimpleTooltip>
    );

  return (
    <>
      {modal && (
        <AnalysisShareModal
          analysisId={analysisId}
          type={type}
          onClose={() => setModal(false)}
        />
      )}
      {button}
    </>
  );
};

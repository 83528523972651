import React from 'react';
import { Box, Button, Heading, Input, Text, tombac } from 'tombac';

export type UrlResponse =
  | { status: 'success'; url: string }
  | { status: 'generating' }
  | { status: 'failed' }
  | { status: 'idle' }
  | { status: 'loading' };

interface AnalysisShareProperties {
  title: string;
  subtitle?: string;
  url: string;
  onCopy: () => void;
  onDelete?: () => void;
}

export const AnalysisShareSection: React.FC<AnalysisShareProperties> = ({
  title,
  subtitle,
  url,
  onCopy,
  onDelete,
}) => {
  return (
    <Box>
      <Heading level={6} $marginBottom="5px">
        {title}
      </Heading>
      {subtitle ? (
        <Text $marginBottom="5px" $color={tombac.color('neutral', 700)}>
          {subtitle}
        </Text>
      ) : null}
      <Box
        $display="grid"
        $gridTemplateColumns="300px 100px 100px"
        $gridColumnGap="10px"
      >
        <Input $width="300px" data-test="share-link-input" value={url} />
        <Button onClick={onCopy}>Copy link</Button>
        {onDelete ? (
          <Button variant="flat" onClick={onDelete}>
            Delete
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

import { ReactNode } from 'react';
import ChevronLeft from 'react-feather/dist/icons/chevron-left';
import ChevronRight from 'react-feather/dist/icons/chevron-right';
import ReactPaginate from 'react-paginate';
import { Box, Text, tombac } from 'tombac';
import './Pagination.css';

interface PaginationProps {
  pageCount: number;
  page: number;
  pageRangeDisplayed?: number;
  fromCount: number;
  toCount: number;
  totalCount: number;
  onChange: (event: { selected: number }) => void;
  contentLeft?: ReactNode;
  contentRight?: ReactNode;
}

export const Pagination: React.FC<PaginationProps> = ({
  pageCount,
  page,
  onChange,
  pageRangeDisplayed = 5,
  fromCount,
  toCount,
  totalCount,
  contentLeft,
  contentRight,
}) => {
  return (
    <Box
      $display="flex"
      $justifyContent="space-between"
      $alignItems="center"
      $height="32px"
      $marginTop="24px"
      $marginBottom="16px"
    >
      <Box $display="flex" $alignItems="center" $gridColumnGap="10px">
        {contentLeft}
        <Text
          size="s"
          $color={tombac.color('neutral', 700)}
          fontFamily={tombac.altFontFamily}
        >
          {fromCount} - {toCount} of {totalCount} items
        </Text>
      </Box>
      <Box $display="flex" $alignItems="center" $gridColumnGap="10px">
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={pageRangeDisplayed}
          marginPagesDisplayed={1}
          forcePage={page}
          containerClassName="Pagination"
          pageClassName="Pagination__item"
          activeClassName="Pagination__item--active"
          disabledClassName="Pagination__item--disabled"
          previousClassName="Pagination__item"
          nextClassName="Pagination__item"
          previousLabel={<ChevronLeft size={16} />}
          nextLabel={<ChevronRight size={16} />}
          onPageChange={onChange}
        />
        {contentRight}
      </Box>
    </Box>
  );
};

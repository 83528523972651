import { FC } from 'react';
import { Select } from 'tombac';
import { MapMatchOption } from 'model/AnalysisParameters';

interface Option {
  value: MapMatchOption;
  label: string;
}

interface Props {
  options: Option[];
  value: MapMatchOption;
  onChange: (option: MapMatchOption) => void;
}

export const MapMatchSelect: FC<Props> = ({ options, value, onChange }) => {
  return (
    <Select<Option>
      value={options.find((o) => o.value === value)}
      onChange={(option: Option) => onChange(option.value)}
      options={options}
    />
  );
};

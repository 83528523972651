import { AnalysisTablePage } from 'components/AnalysisTable/AnalysisTablePage';
import { Title } from '../Layout/Title';

export const SupportPage: React.FC = () => {
  return (
    <div className="SupportPage">
      <Title titleSuffix="Support" />
      <AnalysisTablePage
        title="Users' reports"
        path="/support"
        showDrafts={false}
        adminView={true}
      />
    </div>
  );
};

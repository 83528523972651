import { FC } from 'react';
import { Title } from 'components/Layout/Title';
import { analysisUrl } from 'logic/analysis/analysisUtils';
import { useHistory, useLocation } from 'react-router-dom';
import { useAnalysisContext } from '../AnalysisViewPage';
import NavBar from './NavBar';
import NavBarBack from './NavBarBack';
import NavBarItem from './NavBarItem';
import NavBarTitle from './NavBarTitle';

interface Props {
  visualisationName: string;
  children?: React.ReactNode;
}

export const ViewPageNavbar: FC<Props> = ({ visualisationName, children }) => {
  const history = useHistory();
  const location = useLocation();
  const { analysis } = useAnalysisContext();

  const backPathname = analysisUrl(
    analysis.info.type,
    analysis.info.id,
    location.pathname.includes('/share/') ? 'share' : 'view',
  );

  return (
    <NavBar>
      <Title titleSuffix={visualisationName} />
      <NavBarBack
        onClick={() =>
          history.push({
            pathname: backPathname,
            search: location.search,
          })
        }
      >
        <NavBarTitle>{analysis.info.name}</NavBarTitle>
      </NavBarBack>
      {visualisationName && <NavBarItem>{visualisationName}</NavBarItem>}
      {children}
    </NavBar>
  );
};

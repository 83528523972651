import * as React from 'react';
import styled from 'styled-components';
import { tombac } from 'tombac';
import { useMap, getFirstLabelLayer } from 'legoland-shared';
import { MapTypes } from 'reducers/menuReducer';
import {
  IconImage,
  Lines,
  Source,
  Symbols,
} from 'components/AnalysisNew/SelectedLink/MapboxComponents';
import { SelectedLinkFeature } from 'components/AnalysisNew/SelectedLink/SelectedLinkFeature';
import { queryFeatures } from 'components/Map/mapUtils';
import { useMapEvent } from 'hooks/useMapEvent';
import { useMapSymbolVisibility } from 'hooks/useMapSymbolVisibility';
import arrowTriangleAlpha from './arrow-triangle-alpha.svg';
import { useColorfullArrows } from './colorfullArrows';
import { LinkInfo } from './SelectedLinkInfo/LinkInfo';
import { LinkNode } from './LinkNode';
import { PaletteScale } from './palette/ColorPalette';
import {
  NodeFilter,
  SegmentStats,
  SelectedLinkUnit,
  SelectedSegment,
  View,
} from 'model/SelectedLink';
import { useTreeRenderer } from './useTreeRenderer';
import { LinkRegionInfo } from './SelectedLinkInfo/LinkRegionInfo';
import { EntrancesExistsInfo } from './SelectedLinkInfo/EntrancesExitsInfo';
import { TrimsInfoPopup } from './TrimsInfoPopup';
import { feature, Feature } from '@turf/turf';
import { SELECTED_LINK_TRIMS_INFO_RELEASE_DATE } from 'logic/time/releaseDates';
import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';

const SEGMENT_CONNECTIONS_VISIBILITY_ZOOM = 17;

const SegmentsContainer = styled.div`
  position: absolute;
  top: ${tombac.space(2)};
  right: 50px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin: 0;
  max-height: 60vh;
  width: 380px;
  overflow-y: auto;
  border-radius: 10px;
`;

export const SegmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${tombac.space(1.5)};
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  width: 360px;
`;

export interface SelectedSegmentConnection {
  feature: Feature<any>;
  active: boolean;
}

export const scale = PaletteScale.makeMinMax(0, 100, 10);

interface Props {
  mapTypeName: MapTypes;
  tree: LinkNode;
  unit: SelectedLinkUnit;
  selectedSegments: SelectedSegment[];
  selectedSegmentsStats: SegmentStats[];
  hideRootSegment: boolean;
  getColor: (value: number) => string;
  onSelectedSegmentsChange: (
    segments: SelectedSegment[],
    lngLat?: mapboxgl.LngLat,
  ) => void;
  isSelectedLinkRegion: boolean;
  view: View;
  nodeFilter: NodeFilter;
  hideInfo: boolean;
  hover: { nodes: Set<LinkNode> };
  onHoverChange: (hover: { nodes: Set<LinkNode> }) => void;
  onNodeFilterChange: (nodeFilter: NodeFilter) => void;
  onIsHoveredChange: (isHovered: boolean) => void;
  shouldShowNode: (node: LinkNode, ignoreHighlighted?: boolean) => boolean;
}

export const SelectedLinkTree: React.FC<Props> = ({
  mapTypeName,
  tree,
  unit,
  selectedSegments,
  selectedSegmentsStats,
  hideRootSegment,
  onSelectedSegmentsChange,
  getColor,
  isSelectedLinkRegion,
  view,
  nodeFilter,
  hideInfo,
  hover,
  onHoverChange,
  onNodeFilterChange,
  onIsHoveredChange,
  shouldShowNode,
}) => {
  const { analysis } = useAnalysisContext();
  const [
    selectedSegmentConnection,
    setSelectedSegmentConnection,
  ] = React.useState<SelectedSegmentConnection>();
  const [id] = React.useState(() => String(Math.random()));
  const { map } = useMap();

  const layers = {
    backgroundLinks: `background-links-${id}`,
    linksIcons: `links-icons-${id}`,
    arrowTriangleAlpha: `arrow-triangle-alpha-${id}`,
  };

  const {
    allLinks,
    icons,
    links,
    selectedSegmentsGeometry,
    selectedLink,
    hoverSegments,
  } = useTreeRenderer({
    root: tree,
    getColor,
    unit,
    shouldShowNode,
    selectedSegments,
    hover,
  });

  useMapEvent('click', (e: mapboxgl.MapMouseEvent) => {
    if (selectedSegmentConnection) {
      setSelectedSegmentConnection({
        ...selectedSegmentConnection,
        active: true,
      });
      return;
    }
    const features = queryFeatures(e, [layers.backgroundLinks]);

    let selection: SelectedSegment[] = [];

    let allPaths: number[][] = [];

    features.forEach((it) => {
      // Check if the clicked path is already selected
      const paths = tree
        .getByHash(it.feature.properties?.hash)
        .map((it) => it.path);

      const isSameBranch = paths.some((path) =>
        allPaths.some((it) => LinkNode.isSameBranch(it, path)),
      );
      if (isSameBranch) return;

      allPaths.push(...paths);
      selection.push({
        segmentHash: it.feature.properties?.hash,
      });
    });
    if (selection.length > 0) {
      onSelectedSegmentsChange(selection, e.lngLat);
    }

    onNodeFilterChange({ exclude: new Set() });
  });

  useMapEvent('mousemove', (e: mapboxgl.MapMouseEvent) => {
    const segmentsFeatures = queryFeatures(e, [layers.backgroundLinks]);

    // get connections between segments to show trims info only if zoom is greater than selected
    const segmentConnectionsFeatures =
      map.getZoom() > SEGMENT_CONNECTIONS_VISIBILITY_ZOOM
        ? queryFeatures(e, [layers.linksIcons], 0).filter(
            (it) => it.distance < 0.001, // get only when pointer exactly on the connection
          )
        : [];

    onIsHoveredChange(
      segmentsFeatures.length > 0 || segmentConnectionsFeatures.length > 0,
    );

    if (segmentsFeatures.length === 0) {
      onHoverChange({ nodes: new Set() });
    } else {
      const nodes = tree.getByHash(
        segmentsFeatures[0].feature.properties?.hash,
      );
      onHoverChange({ nodes: new Set(nodes) });
    }

    if (
      selectedSegmentConnection?.active ||
      new Date(analysis.info.creationTime) <
        SELECTED_LINK_TRIMS_INFO_RELEASE_DATE
    ) {
      return;
    }

    if (segmentConnectionsFeatures.length === 0) {
      setSelectedSegmentConnection(undefined);
    } else {
      const hoveredFeature = segmentConnectionsFeatures[0].feature;
      setSelectedSegmentConnection({
        feature: feature(hoveredFeature.geometry, hoveredFeature.properties),
        active: false,
      });
    }
  });

  useMapSymbolVisibility({ poi: false, shields: true });

  useColorfullArrows(getColor);

  const firstLabelLayerId = React.useMemo(
    () => getFirstLabelLayer(map, 'road-labels', mapTypeName),
    [map, mapTypeName],
  );

  return (
    <>
      <SegmentsContainer>
        {isSelectedLinkRegion && (
          <EntrancesExistsInfo
            unit={unit}
            getColor={getColor}
            tree={tree}
            selectedSegments={selectedSegments}
            view={view}
          />
        )}
        {!hideInfo && selectedSegments.length > 0 && (
          <>
            {isSelectedLinkRegion ? (
              <LinkRegionInfo
                selectedSegments={selectedSegments}
                setSelectedSegments={onSelectedSegmentsChange}
                setNodeFilter={onNodeFilterChange}
                nodeFilter={nodeFilter}
                setHover={onHoverChange}
                tree={tree}
                getColor={getColor}
                unit={unit}
                shouldShowNode={shouldShowNode}
                view={view}
              />
            ) : (
              <LinkInfo
                selectedSegments={selectedSegments}
                selectedSegmentsStats={selectedSegmentsStats}
                setSelectedSegments={onSelectedSegmentsChange}
                setNodeFilter={onNodeFilterChange}
                nodeFilter={nodeFilter}
                setHover={onHoverChange}
                tree={tree}
              />
            )}
          </>
        )}
      </SegmentsContainer>
      <Source data={allLinks}>
        <Lines
          id={layers.backgroundLinks}
          line-color="rgba(210,210,210,0.8)"
          line-width={2}
          beforeId={firstLabelLayerId}
        />
      </Source>
      <Source data={hoverSegments}>
        <Lines
          line-color="rgba(210,210,210,0.8)"
          line-width={22}
          line-cap="round"
          beforeId={firstLabelLayerId}
        />
      </Source>
      <Source data={links} generateId>
        <IconImage id={layers.arrowTriangleAlpha} src={arrowTriangleAlpha} />
        <Lines
          line-cap="round"
          line-color={['get', 'line-color']}
          line-width={['get', 'line-width']}
        />
        <Symbols
          icon-size={[
            'max',
            ['min', ['/', ['get', 'line-width'], 8], 1.0],
            0.6,
          ]}
          icon-image={layers.arrowTriangleAlpha}
          symbol-avoid-edges
          symbol-placement="line"
          symbol-spacing={20}
        />
        <Symbols
          text-field="{text}"
          symbol-placement="line-center"
          text-font={['Noto-Bold']}
          text-letter-spacing={0.05}
          text-size={['get', 'text-size']}
          text-color={['get', 'text-color']}
          text-halo-color={['get', 'text-halo-color']}
          text-halo-width={1.5}
        />
      </Source>

      <Source data={icons}>
        <Symbols
          id={layers.linksIcons}
          icon-image={[
            'case',
            [
              '==',
              ['get', 'id'],
              selectedSegmentConnection?.feature?.properties?.id ?? null, //change icon color on hover
            ],
            ['concat', ['get', 'icon-image'], '-hover'],
            [
              'concat',
              ['get', 'icon-image'],
              '-',
              ['get', 'icon-image-color-index'],
            ],
          ]}
          icon-size={['get', 'icon-size']}
          icon-rotate={['get', 'icon-rotate']}
          filter={[
            'all',
            [
              //hide segment connection if zoom is less than selected
              'any',
              ['!=', ['get', 'two-links-connection'], true],
              ['>=', ['zoom'], SEGMENT_CONNECTIONS_VISIBILITY_ZOOM],
            ],
            [
              //hide segment connection if all trims are 0
              'any',
              ['!=', ['get', 'processing-failures'], 0],
              ['!=', ['get', 'privacy-trims'], 0],
              ['!=', ['get', 'trips-lost'], 0],
            ],
          ]}
        />
      </Source>

      {selectedSegmentConnection?.active && (
        <TrimsInfoPopup
          onClose={() => {
            setSelectedSegmentConnection(undefined);
          }}
          selectedSegmentConnection={selectedSegmentConnection}
        />
      )}

      {!isSelectedLinkRegion && selectedLink && !hideRootSegment ? (
        <SelectedLinkFeature links={[selectedLink]} />
      ) : null}
      {selectedSegmentsGeometry.map((it, i) =>
        it ? <SelectedLinkFeature links={[it]} key={i} /> : null,
      )}
    </>
  );
};

import {
  AccessType,
  MeDetailsSafe,
  MeDto,
  queryClient,
  useLegoland,
  useMeDetailsQuery,
  UserRole,
  UserSettings,
  useUserProduct,
} from 'legoland-sdk/dist/experimental';
import React, { useEffect, useState } from 'react';
import { FormGroup, Select } from 'tombac';
import { useTimerFunction } from './useTimerFunction';
import { DebugWindow } from './DebugWindow';

const useKeyPress = (key: string, callback: () => void) => {
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === key) {
        callback();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [key]);

  return null;
};

export const DebugTools: React.FC = () => {
  const isDev = process.env.NODE_ENV === 'development';
  const [show, setShow] = useState(false);
  useKeyPress('`', () => isDev && setShow((it) => !it));

  return show ? <Debug onClose={() => setShow(false)} /> : null;
};

interface MeDtoResponse {
  me: MeDto;
}

interface MeDetailsSafeResponse {
  meDetails: MeDetailsSafe;
}

interface DebugWindowProps {
  onClose: VoidFunction;
}

interface Option<T = string> {
  value: T;
  label: string;
}

const userRoleOptions: Option<UserRole>[] = [
  { label: 'Admin', value: UserRole.Admin },
  { label: 'User', value: UserRole.User },
  { label: 'Support', value: UserRole.Support },
];

const dateFormatOptions: Option[] = [
  { value: 'DDMMYYYY', label: 'DDMMYYYY' },
  { value: 'MMDDYYYY', label: 'MMDDYYYY' },
];

const unitOptions: Option<UserSettings['units']>[] = [
  { value: 'KM', label: 'Km' },
  { value: 'MI', label: 'Miles' },
];

const accessTypeOptions: Option<AccessType>[] = Object.entries(AccessType).map(
  ([key, value]) => ({
    label: key,
    value,
  }),
);

const Debug: React.FC<DebugWindowProps> = ({ onClose }) => {
  const meDetails = useMeDetailsQuery()?.data?.meDetails;
  const settings = meDetails?.settings;
  const product = useUserProduct();
  const [role, setRole] = useState(meDetails?.role);
  const [accessType, setAccessType] = useState<AccessType | undefined>(
    'accessType' in product.access && product.access.accessType,
  );
  const [dateFormat, setDateFormat] = useState(settings?.dateFormat);
  const [units, setUnits] = useState(settings?.units);
  const { productId } = useLegoland();

  const authQueryId = ['auth', 'me'];
  const legolandQueryId = ['legoland', 'users', 'me', 'details'];
  useTimerFunction(
    () => {
      queryClient.setQueryData<MeDtoResponse | undefined>(
        authQueryId,
        (response) => {
          if (!response.me || !role) return;

          response.me.role = role;

          return { ...response };
        },
      );
      queryClient.setQueryData<MeDetailsSafeResponse | undefined>(
        legolandQueryId,
        (response) => {
          if (!response.meDetails) return;

          if (role) {
            response.meDetails.role = role;
          }
          if (accessType) {
            const product = response.meDetails.products?.find(
              (p) => p.id === productId,
            );
            if (product && 'accessType' in product.access) {
              product.access.accessType = accessType;
            }
          }
          if (dateFormat) {
            response.meDetails.settings.dateFormat = dateFormat;
          }
          if (units) {
            response.meDetails.settings.units = units;
          }
          return { ...response };
        },
      );
    },
    2000,
    [role, accessType, dateFormat, units],
  );

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(legolandQueryId);
      queryClient.invalidateQueries(authQueryId);
    };
  }, []);

  return (
    <DebugWindow onClose={onClose}>
      <FormGroup label="Role">
        <Select<Option<UserRole>>
          options={userRoleOptions}
          menuPlacement="top"
          value={userRoleOptions.find((o) => o.value === role)}
          onChange={(option: Option<UserRole>) => {
            setRole(option.value);
          }}
        />
      </FormGroup>
      <FormGroup label="Access Type" $mt="1sp">
        <Select<Option<AccessType>>
          options={accessTypeOptions}
          value={accessTypeOptions.find((o) => o.value === accessType)}
          menuPlacement="top"
          onChange={(option: Option<AccessType>) => setAccessType(option.value)}
        />
      </FormGroup>
      <FormGroup label="Date Format" $mt="1sp">
        <Select<Option>
          options={dateFormatOptions}
          onChange={(option: Option) => setDateFormat(option.value)}
          value={dateFormatOptions.find((o) => o.value === dateFormat)}
          menuPlacement="top"
        />
      </FormGroup>
      <FormGroup label="Units" $mt="1sp">
        <Select<Option<UserSettings['units']>>
          options={unitOptions}
          onChange={(option: Option<UserSettings['units']>) =>
            setUnits(option.value)
          }
          value={unitOptions.find((o) => o.value === units)}
          menuPlacement="top"
        />
      </FormGroup>
    </DebugWindow>
  );
};

import React, { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';
import AnalysisApi from 'api/AnalysisApi';
import { Box, Button, Text, useToasts } from 'tombac';
import { AnalysisType } from 'model/AnalysisDto';
import { AnalysisShareSection } from './AnalysisShareSection';

type UrlResponse =
  | { status: 'success'; url: string }
  | { status: 'generating' }
  | { status: 'failed' }
  | { status: 'idle' }
  | { status: 'loading' };

interface AnalysisShareProperties {
  analysisId: number;
  type: AnalysisType;
  path?: string;
  searchQuery?: string;
}

const analysisTypeToPath: { [key in AnalysisType]: string } ={
  [AnalysisType.FlowMatrix]: '',
  [AnalysisType.SelectedLink]: 'selected-link/',
  [AnalysisType.FlowLinks]: 'flow-links/',
};

export const shareUrlWarning = `Anyone with this link can view this analysis`;

export const AnalysisShare: React.FC<AnalysisShareProperties> = ({
  analysisId,
  type,
  path = '',
  searchQuery = '',
}) => {
  const toast = useToasts();
  const [shareUrl, setShareUrl] = useState<UrlResponse>({ status: 'idle' });
  const makeUrl = (token: string) =>
    `${location.origin}/share/${analysisTypeToPath[type]}${analysisId}${path}?t=${token}`;
  const myUrl =
    window.location.href +
    (window.location.href.includes('?') ? '&' : '?') +
    searchQuery;

  useEffect(() => {
    setShareUrl({ status: 'loading' });
    const load = async () => {
      const token = await AnalysisApi.getSharedToken(analysisId);
      if (token !== '') {
        setShareUrl({ status: 'success', url: makeUrl(token) });
      } else {
        setShareUrl({ status: 'idle' });
      }
    };
    load();
  }, [analysisId]);

  const generateLink = async () => {
    setShareUrl({ status: 'generating' });
    try {
      const token = await AnalysisApi.share(analysisId);
      setShareUrl({ status: 'success', url: makeUrl(token) });
    } catch (e) {
      setShareUrl({ status: 'failed' });
    }
  };

  const copyLink = (url: string) => {
    copy(url);
    toast.addToast('Link copied to clipboard', 'success');
  };

  const deleteLink = async () => {
    await AnalysisApi.deleteSharedToken(analysisId);
    toast.addToast('Shareable link deleted', 'success');
    setShareUrl({ status: 'idle' });
  };

  return (
    <>
      {shareUrl.status === 'loading' && <>Loading</>}
      {shareUrl.status === 'idle' && (
        <Box>
          <Text $marginBottom="15px">
            You can create a link that will allow anyone to view this analysis.
          </Text>
          <Button onClick={generateLink}>Create sharable link</Button>
        </Box>
      )}
      {shareUrl.status === 'generating' && <Button>Generating...</Button>}
      {shareUrl.status === 'failed' && 'Failed to generate link'}
      {shareUrl.status === 'success' && shareUrl.url && (
        <>
          <AnalysisShareSection
            title="Shared link to the analysis"
            subtitle={shareUrlWarning}
            url={shareUrl.url}
            onCopy={() => copyLink(shareUrl.url)}
            onDelete={deleteLink}
          />
          {searchQuery ? (
            <AnalysisShareSection
              title="Shared link to the current view"
              subtitle={shareUrlWarning}
              url={shareUrl.url + '&' + searchQuery}
              onCopy={() => copyLink(shareUrl.url + '&' + searchQuery)}
            />
          ) : null}
        </>
      )}
      {searchQuery ? (
        <AnalysisShareSection
          title="Link to the current view for yourself"
          subtitle="Only you are authorized to access this link"
          url={myUrl}
          onCopy={() => copyLink(myUrl)}
        />
      ) : null}
    </>
  );
};
